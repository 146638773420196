<template>
  <div>
    <div class="white-box">
      <h3 class="mb-2">Account Email</h3>
      <p>The email ussed to login across all our services</p>
      <div class="form-group">
        <label asp-for="Current Email" class="">Current Email</label>
        <input class="form-control" :placeholder="primaryEmail" asp-for="Current Email" disabled>
      </div>
    </div>
    
    <form class="white-box" @keydown.enter.prevent="onSaveClicked">
      <h3 class="mb-2">Change Email</h3>
      <p>In case you want to change your main email address, please enter the new email below and click “Save”</p>
      <div class="form-group">
          <label asp-for="New Email" class="">New Email</label>
          <input class="form-control" type="email" placeholder="Please enter your new email address" asp-for="New Email" 
            v-model="newEmail"
            v-bind:class="{'is-invalid': validation.invalid.NewEmail }"
            @input="activateBtns" >
          <div class="invalid-feedback"  v-for="(error, index) in validation.invalid.NewEmail" v-bind:key="index">{{ error }}</div>
      </div>
    </form>
    
    <div class="btns-group d-flex justify-content-center justify-content-sm-end">
      <mdb-btn type="button" class="btn--back ml-sm-auto" rounded  
        :class="[activeBtns ? 'd-block' : 'd-none']"
        @click="onCancelClicked">Cancel</mdb-btn>
      <mdb-btn type="button" class="" rounded 
        :class="[activeBtns ? 'btn--primary' : 'btn--disabled disabled']"
        @click="onSaveClicked">Save Changes</mdb-btn>
    </div>
    
    <return-back-modal ref="returnBackModalComponent" 
      @goBack="sendTitle"></return-back-modal>

    <verify-modal ref="verifyModalComponent" 
      :verifyId="verifyId"
      :primaryEmail="primaryEmail"
      :newEmail="newEmail"
      :returnUrl="returnUrl"
      :stogeryToken="stogeryToken"
      @updateEmail="updateEmail"></verify-modal>

  </div>
</template>

<script>
import { ref } from 'vue';
import returnBackModal from '../../return-back-modal.vue';
import verifyModal from '../../verify-modal.vue';
import { post } from '../../../services/http-common.js';

export default {
  components: { returnBackModal, verifyModal },
  name: "PersonalEmail",
  data() {
    return {
      activeBtns: false,
      newEmail: '',
      verifyId: String,
      validation: {
          invalid: {},
          valid: {},
      }
    }
  },
  props: {
    primaryEmail: ref(String),
    returnUrl: ref(String),
    stogeryToken: ref(String),
  },
  methods: {
    sendTitle() {
      this.$emit("showComponent", 'Personal Info');
    },
    activateBtns() {
      this.activeBtns = true;
    },
    // opening returnBackModal
    onCancelClicked() {
      this.$refs.returnBackModalComponent.showModal(true);
    },
    onSaveClicked() {
      let data = new FormData();
      data.append("ReturnUrl", this.returnUrl);
      data.append("NewEmail", this.newEmail)

      post('/Account/Profile/EditEmail', data, this.stogeryToken)
          .then((response) => {
            this.verifyId = response.data.verifyId;
            this.returnUrl = response.data.returnUrl;
            this.$refs.verifyModalComponent.showModal(true);

            this.resetValidation()
          })
          .catch((error) => {
              if (error.response) {
                  this.validation.invalid = error.response.data;
              // Request made and server responded
              } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              }
          })
    },
    updateEmail(newEmail) {
      this.primaryEmail = newEmail
      this.newEmail = ''
    },
    resetValidation() {
      this.validation = {
          invalid: {},
          valid: {},
      }
    }
  }
}
</script>
