import axios from 'axios';
import he from "vue2-datepicker/locale/es/he";

// const BASE_URL = 'https://localhost:5001/';
// const TOKEN = localStorage.getItem('token');

export async function get(url, params) {
    if (params) {
        return axios.get(url, {
            'headers': setGetHeaders(),
            'params': params
        })
    } else {
        return axios.get(url, {
            'headers': setGetHeaders()
        })
    }
}

export async function post(url, data, headers) {

    let requestHeaders;
    if (typeof (headers) === "object") {
        let defaultHeaders;
        if ("token" in headers) {
            defaultHeaders = setHeaders(headers.token);
        } else if ("RequestVerificationToken" in headers) {
            defaultHeaders = setHeaders(headers.RequestVerificationToken);
        } else {
            defaultHeaders = setGetHeaders();
        }

        requestHeaders = {...headers, ...defaultHeaders};
    } else {
        requestHeaders = setHeaders(headers);
    }

    return axios.post(url, data, {
        'headers': requestHeaders
    })
}

function setHeaders(token) {
    return {
        'RequestVerificationToken': token,
        'X-Requested-With': 'XMLHttpRequest'
    };
}

function setGetHeaders() {
    return {
        'X-Requested-With': 'XMLHttpRequest'
    };
}