<template>
    <div class="page-title"> 
        <div class="d-flex flex-column flex-sm-row align-items-lg-center">
            <mdb-btn v-if="showBtn" class="btn--outline d-flex align-items-center align-self-start align-self-sm-center py-2 mr-3 ml-0" @click="onCancelClicked">
                <i class="icon icon-arrow-left mr-2"></i>
                Back <span class="d-inline d-sm-none ml-1">to {{parentSection}}</span>
            </mdb-btn>
            <h2 class="font-weight-bold m-0">{{title}}</h2>
        </div>
        <p>{{text}}</p>
        <return-back-modal ref="modalComponent" @goBack="sendBack"></return-back-modal>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import returnBackModal from './return-back-modal';
    export default {
        components: { returnBackModal },
        name: 'pageTitle',
        props: {
            showBtn: false,
            title: ref(String),
            text: ref(String),
            parentSection: ref(String)
        },
        methods: {
            // opening returnBackModal
            onCancelClicked() {
                this.$refs.modalComponent.showModal(true);
            },
            sendBack() {
                this.$emit("sendBack", this.parentSection);
            }
        }
    }
</script>

<style lang="scss">
    .page-title {
        width: calc(100% - 230px);
        padding: 20px 40px;
        font-weight: 400;
        line-height: 36px;
        font-size: 20px;
        @include respond-to(max-width, 1199px) {
            padding: 20px 0 20px 40px;
            line-height: 36px;
            font-size: 20px;
        }
        @include respond-to(max-width, 991px) {
            width: 100%;
            padding: 20px 15px;
        }
        @include respond-to(max-width, 575px) {
            padding: 20px 0 5px;
            line-height: normal;
            font-size: 16px;
        }
        h2 {
            font-size: 48px;
            line-height: 60px;
            @include respond-to(max-width, 1199px) {
                line-height: 44px;
                font-size: 32px;
            }
            @include respond-to(max-width, 575px) {
                line-height: 40px;
                font-size: 28px;
            }
        }
        .btn--outline {
            @include respond-to(max-width, 575px) {
                border: 0 !important;
                margin: 0 0 20px !important;
                padding: 0 !important
            }
            &:hover {
                @include respond-to(max-width, 575px) {
                    background-color: transparent !important;
                    color: #000 !important;
                }
            }
            &.btn-default:not([disabled]):not(.disabled):active {
                @include respond-to(max-width, 575px) {
                    background-color: transparent !important;
                }
            }
        }
    }
</style>