<template>
    <div>
        <div class="white-box" v-if="this.component === 'Personal Info'">
            <h3 class="mb-2">Basic Info:</h3>
            <p>Changing any of the fields below will be relfected across all our services</p>
            <info-row title="Avatar" text="Auto-generated from initials" :abbr=abbr notClickable = true></info-row>
            <info-row @showComponent="showComponent" title="Name" :text=name></info-row>
        </div>
        <personal-name v-if="this.component === 'Name'" @showComponent="showComponent" @updatedNames="updatedNames" :firstNameProp = firstName :lastNameProp = lastName 
        :saveNamesPathProp = saveNamesPath :stogeryTokenNameProp = stogeryTokenName :stogeryTokenProp = stogeryToken></personal-name>
    </div>
</template>

<script>
import { ref } from 'vue';
import infoRow from '../../info-row.vue';
import PersonalName from './personal-name.vue';
  export default {
    components: { infoRow, PersonalName },
    name: 'basicInfoComponent',
    data(){ 
        return {
            component: "Personal Info",
            abbr: this.abbrProp,
            name: this.nameProp,
            firstName: this.firstNameProp,
            lastName: this.lastNameProp,
            saveNamesPath: this.saveNamesPathProp,
            stogeryTokenName: this.stogeryTokenNameProp,
            stogeryToken: this.stogeryTokenProp,
            returnPersonalInfoComponent: this.returnPersonalInfo
        }   
    },
    props: {
        abbrProp: ref(String),
        nameProp: ref(String),
        firstNameProp: ref(String),
        lastNameProp: ref(String),
        saveNamesPathProp: ref(String),
        stogeryTokenNameProp: ref(String),
        stogeryTokenProp: ref(String),
        returnPersonalInfo: ref(Boolean)
    },
    watch: {
        // On back button show Security 
        returnPersonalInfo(newreturnPersonalInfo) {
            this.returnPersonalInfoComponent = newreturnPersonalInfo;
        },
        returnPersonalInfoComponent(newreturnPersonalInfo, oldreturnPersonalInfo) {
            if(newreturnPersonalInfo === true) {
                this.component = 'Personal Info';
                this.returnPersonalInfoComponent = false;
            }
       }
    },
    methods: {
        showComponent(component) {
            this.component = component;
            this.$emit("changeTitle", this.component);
        },
        updatedNames(updatedFirstName, updatedLastName) {
            this.firstName = updatedFirstName;
            this.lastName = updatedLastName;
            this.name = this.firstName +' '+ this.lastName;
            this.abbr = this.firstName.charAt(0) + this.lastName.charAt(0);
        }
    }
  }
</script>