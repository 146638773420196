<template>
    <div class="product product--returns">
        <a :href="link" class="product__content text-center font-weight-bold">
            {{ name }} <i class="icon icon-arrow-right-thin"></i>
        </a>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: 'ProductComponent',
    data(){ 
        return {
            link: this.productLinkProp,
            name: this.productNameProp
        }   
    },
    props: {
        productLinkProp: ref(String),
        productNameProp: ref(String)
    }
}
</script>

<style lang="scss" scoped>
    a {
        display: flex!important;
        align-items: center;
        font-size: 18px!important;
        padding: 20px!important;
        color: #031C2B;
    }
    .icon {
      @include themify($themes) {
        color: themed('sidebarActiveItem');
      }
      &.icon-arrow-right-thin {
        margin-left: 24px;
        font-size: 20px!important;
      }
    }
</style>