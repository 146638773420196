<template>
  <div>
    <div class="white-box" v-if="this.component === 'Security'">
      <h3 class="mb-2">Signing in to {{this.clientName}}</h3>
      <p>Changing any of the settings below will be relfected across all our services</p>
      <info-row @showComponent="showComponent"
                title="Password"
                :text="lastChangedOnString"></info-row>
      <info-row @showComponent="showComponent"
                title="2-FACTOR LOGIN"
                :text="mfaStatusString"></info-row>
      <info-row v-if="this.mfaStatus === true"
                @showComponent="showComponent"
                title="Backup Codes"
                text="In case you lose access  to your account"></info-row>
    </div>
    <change-password v-if="this.component === 'Password'"
                     @showComponent="showComponent"
                     :stogeryToken="stogeryToken"
                     :returnUrl="returnUrl"
                     :getPasswordStatus="getPasswordStatus"
                     :editPassword="editPassword"
                     :lastChangedOnString="lastChangedOnString"
                     @timeUpdatedPassword="timeUpdatedPassword"></change-password>
    <mfa v-if="this.component === '2-FACTOR LOGIN'"
         @showComponent="showComponent"
         :returnUrl="returnUrl"
         :stogeryName="stogeryName"
         :stogeryToken="stogeryToken"
         :enableMfa="enableMfa"
         :qrSrc="qrSrc"
         :qrAlt="qrAlt"
         :disableMFAGoogleAuth="disableMFAGoogleAuth"
         @updateMFAStatusEnabled="updateMFAStatusEnabled"
         :mfaStatusProp="mfaStatus"
         @updateMFAStatusDisabled="updateMFAStatusDisabled"
         :disableMFABackupCode="disableMFABackupCode"></mfa>
    <backup-codes v-if="this.component === 'Backup Codes'"
                  @showComponent="showComponent"
                  :returnUrl="returnUrl"
                  :stogeryName="stogeryName"
                  :stogeryToken="stogeryToken"
                  :getBackUpCodes="getBackUpCodes"
                  :generateBackUpCodes="generateBackUpCodes"
                  :removeBackUpCodes="removeBackUpCodes"></backup-codes>
  </div>
</template>

<script>
import {ref} from 'vue';
import infoRow from '../../info-row.vue';
import BackupCodes from './backup-codes.vue';
import ChangePassword from './change-password.vue';
import Mfa from './mfa.vue';

export default {
  components: {infoRow, BackupCodes, ChangePassword, Mfa},
  name: 'securityBox',
  data() {
    return {
      // component: "Security",
      mfaStatus: this.mfaStatusProp,
      mfaStatusString: '',
      component: this.component2Prop,
      returnSecurityComponent: this.returnSecurity,
      lastUpdatedPasswordDate: this.lastPasswordChangedAt,
      lastChangedOnString: '',
    }
  },
  props: {
    section: ref(String),
    qrSrc: ref(String),
    qrAlt: ref(String),
    returnUrl: ref(String),
    enableMfa: ref(String),
    stogeryName: ref(String),
    stogeryToken: ref(String),
    disableMFAGoogleAuth: ref(String),
    mfaStatusProp: ref(Boolean),
    disableMFABackupCode: ref(String),
    getBackUpCodes: ref(String),
    generateBackUpCodes: ref(String),
    removeBackUpCodes: ref(String),
    component2Prop: ref(String),
    returnSecurity: ref(Boolean),
    getPasswordStatus: ref(String),
    editPassword: ref(String),
    lastPasswordChangedAt: ref(String),
    clientName: ref(String)
  },
  watch: {
    // On back button show Security 
    returnSecurity(newReturnSecurity) {
      this.returnSecurityComponent = newReturnSecurity;
    },
    returnSecurityComponent(newReturnSecurity, oldReturnSecurity) {
      if (newReturnSecurity === true) {
        this.component = 'Security';
        this.returnSecurityComponent = false;
      }
    }
  },
  mounted() {
    this.checkSection();
    this.returnStatusString();
    this.timeOfChange();
  },
  methods: {
    timeUpdatedPassword(date) {
      this.lastChangedOnString = "Last change on " + date;
    },
    timeOfChange() {
      if (this.lastUpdatedPasswordDate !== undefined) {
        this.lastUpdatedPasswordDate = new Date(this.lastUpdatedPasswordDate).toDateString().split(' ').slice(1).join(' ');
        this.lastChangedOnString = "Last change on " + this.lastUpdatedPasswordDate;
      } else {
        this.lastChangedOnString = "Still not updated password";
      }
    },
    showComponent(component) {
      this.component = component;
      this.$emit("changeTitle", this.component);
    },
    checkSection() {
      console.log(this.section)
      if (this.section === 'mfa') {
        this.component = '2-FACTOR LOGIN';
        this.$emit("changeTitle", this.component);
      }else if(this.section === 'password') {
        this.component = 'Password';
        this.$emit("changeTitle", this.component);
      }
    },
    updateMFAStatusEnabled() {
      this.mfaStatus = true;
      this.mfaStatusString = 'On';
    },
    updateMFAStatusDisabled() {
      this.mfaStatus = false;
      this.mfaStatusString = 'Off';
    },
    returnStatusString() {
      if (this.mfaStatus === false) {
        this.mfaStatusString = 'Off';
      } else if (this.mfaStatus === true) {
        this.mfaStatusString = 'On';
      }
    }
  }
}
</script>
