<template>
  <div>
    <div class="white-box" v-if="this.component === 'Personal Info'">
      <h3 class="mb-2">Account Erasure Request:</h3>
      <p>Close your account and request erasure of your data</p>
      <!-- Move into different conponent -->
      <info-row @showComponent="showComponent" title="Erasure request" text="Erasure of your data"></info-row>
      <!-- Add component for Erasure the account - outside the white box -->
    </div>
    <erasure-request v-if="this.component === 'Erasure request'" @showComponent="showComponent"></erasure-request>
  </div>
</template>

<script>
import { ref } from 'vue';
import infoRow from '../../info-row.vue';
import ErasureRequest from './erasure-request.vue';
  export default {
  components: { infoRow, ErasureRequest },
    name: 'AccountErasureComponent',
    data(){ 
      return {
        component: "Personal Info",
        returnPersonalInfoComponent: this.returnPersonalInfo
      }   
    },
    props: {
      returnPersonalInfo: ref(Boolean)
    },
    watch: {
        // On back button show Security 
        returnPersonalInfo(newreturnPersonalInfo) {
            this.returnPersonalInfoComponent = newreturnPersonalInfo;
        },
        returnPersonalInfoComponent(newreturnPersonalInfo, oldreturnPersonalInfo) {
            if(newreturnPersonalInfo === true) {
                this.component = 'Personal Info';
                this.returnPersonalInfoComponent = false;
            }
       }
    },
    methods: {
      showComponent(component) {
        this.component = component;
        this.$emit("changeTitle", this.component);
      }
    }
  }
</script>