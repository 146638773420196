<template>
  <div>
    <div class="white-box" v-if="this.component === 'Personal Info'">
      <h3 class="mb-2">Contact Details:</h3>
      <p>Changing any of the fields belowe will be relfected across all our services</p>
      <info-row
          @showComponent="showComponent"
          title="Email"
          :text="primaryEmail"></info-row>

      <info-row
          @showComponent="showComponent"
          title="Phone"
          :isPhone="isPhone"
          :text="allPhones"></info-row>
    </div>

    <personal-email v-if="this.component === 'Email'"
                    @showComponent="showComponent"
                    :returnUrl="returnUrl"
                    :primaryEmail="primaryEmail"
                    :stogeryToken="stogeryToken"></personal-email>

    <personal-phone v-if="this.component === 'Phone'"
                    :key="componentKey"
                    :allPhones="allPhones"
                    :addNewPhone="addNewPhone"
                    :editPhone="editPhone"
                    :deletePhone="deletePhone"
                    :changeMainPhone="changeMainPhone"
                    :stogeryToken="stogeryToken"
                    :returnUrl="returnUrl"
                    @showComponent="showComponent"
                    @updatePhones="updatePhones"></personal-phone>
  </div>
</template>

<script>
import {ref} from 'vue';
import {get} from '../../../services/http-common.js';
import infoRow from '../../info-row.vue';
import PersonalEmail from './personal-email.vue';
import PersonalPhone from './personal-phone.vue';

export default {
  components: {infoRow, PersonalEmail, PersonalPhone},
  name: 'ContactDetailsComponent',
  data() {
    return {
      component: "Personal Info",
      returnPersonalInfoComponent: this.returnPersonalInfo,
      allPhones: Array,
      isPhone: true,
      componentKey: 0,
    }
  },
  props: {
    returnPersonalInfo: ref(Boolean),
    primaryEmail: ref(String),
    mainPhone: ref(Object),
    addNewPhone: ref(String),
    editPhone: ref(String),
    deletePhone: ref(String),
    changeMainPhone: ref(String),
    stogeryToken: ref(String),
    returnUrl: ref(String)
  },
  watch: {
    // On back button show Security 
    returnPersonalInfo(newreturnPersonalInfo) {
      this.returnPersonalInfoComponent = newreturnPersonalInfo;
    },
    returnPersonalInfoComponent(newreturnPersonalInfo, oldreturnPersonalInfo) {
      if (newreturnPersonalInfo === true) {
        this.component = 'Personal Info';
        this.returnPersonalInfoComponent = false;
        this.getAllPhones();
      }
    },
    deletePhone() {
    }
  },
  mounted() {
    this.getAllPhones();
  },
  methods: {
    showComponent(component) {
      this.component = component;
      this.$emit("changeTitle", this.component);
    },

    getAllPhones() {
      let data = new FormData();
      data.append('ReturnUrl', this.returnUrl);

      get('/Account/Phone/All', data)
          .then((response) => {
            response.data.phones.forEach((phone, index) => {
              phone.oldPhoneNumber = phone.phoneNumber;
              phone.oldPhoneCode = phone.phoneCode;
            })
            if (response.data.phones.length > 0) {
              this.allPhones = response.data.phones
            } else {
              this.isPhone = false;
              this.allPhones = 'None';
            }
          })
          .catch((error) => {
            if (error.response) {
              // Request made and server responded
              console.log(error.response);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },
    updatePhones(context) {
      if (context.eventType === undefined) {
        console.log("The property event type required")
        return;
      }

      console.log("Event type:" + context.eventType);

      switch (context.eventType.toUpperCase()) {
        case "CREATE":
          console.log("Set new phone in the list...");
          context.phone.oldPhoneCode = context.phone.phoneCode;
          context.phone.oldPhoneNumber = context.phone.phoneNumber;
          this.allPhones.push(context.phone);
          break;
        case "UPDATE":
          console.log("Start to update the phone...")
          let oldPhoneCode = context.phone.oldPhoneCode;
          let oldPhoneNumber = context.phone.oldPhoneNumber;
          let newPhoneCode = context.phone.phoneCode;
          let newPhoneNumber = context.phone.phoneNumber;

          this.allPhones.forEach(function (phone) {
            if (phone.oldPhoneCode === oldPhoneCode && phone.oldPhoneNumber === oldPhoneNumber) {
              phone.phoneCode = newPhoneCode;
              phone.phoneNumber = newPhoneNumber;
              phone.oldPhoneCode = newPhoneCode;
              phone.oldPhoneNumber = newPhoneNumber;
              phone.isMain = context.phone.isMain;
              console.log("Updated phone record...", phone);
            }
          });
          break;
        case "DELETE":
          console.log("start to delete a phone", context.phone);
          let deletedIndex = this.allPhones.findIndex(x => x.oldPhoneCode === context.phone.phoneCode && x.oldPhoneNumber === context.phone.phoneNumber);
          console.log("Founded Delete Index:" + deletedIndex);
          if (deletedIndex != null && deletedIndex >= 0) {
            delete this.allPhones[deletedIndex];
            this.allPhones = this.allPhones.filter(function (item) {
              return item !== undefined;
            })
            console.log("The phone was deleted successfully", context.phone);
          }
          break;
        case "CHANGE_MAIN":
          console.log("start to change a main phone", context.phone);

          this.allPhones.forEach(function (phone) {
            phone.isMain = phone.phoneCode === context.phone.phoneCode && phone.phoneNumber === context.phone.phoneNumber;
          });
          console.log("the main phone was updated...");
          break;
        case "CREATE_MAIN":
          console.log("Start to add main phone");
          context.phone.oldPhoneCode = context.phone.phoneCode;
          context.phone.oldPhoneNumber = context.phone.phoneNumber;
          this.allPhones = [context.phone];
          console.log("The main phone was created successfully", context.phone);
          break;
      }

      //this.getAllPhones()
      this.componentKey += 1; // Use it to force Vue to re-render
    }
  }
}
</script>