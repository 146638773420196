import Vue from 'vue'
import * as mdbvue from 'mdbvue'
import 'vue2-datepicker/index.css';
import 'vue-tel-input/dist/vue-tel-input.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import carousel from 'vue-owl-carousel'

Vue.config.productionTip = false
window.Vue = Vue;

Vue.use(VueAxios, axios)
Vue.use(VueToast);

//Global components
Vue.component('personal-info', require('./components/personalInfo/personal-info').default);
Vue.component('register', require('./components/Register').default);
Vue.component('register-guest', require('./components/RegisterGuest').default);
Vue.component('dashboard', require('./components/dashboard/dashboard').default);
Vue.component('security', require('./components/security/security').default);
Vue.component('forgot-password', require('./components/forgot-password').default);
Vue.component('external-signup', require('./components/external-signup').default);
Vue.component('guest-forgot-password', require('./components/guest-forgot-password').default);

// Import all Mdb-vue components
for (const component in mdbvue) {
  /* console.log(component) */
  Vue.component(component, mdbvue[component])
}

// Bootstrap Select
Vue.component('VSelect', require('vue-select-picker-bootstrap').default);
// Bootstrap Datepicker
// Vue.component('DatePicker', require('vue2-datepicker').default);
// Vue tel input
Vue.component('VueTelInput', require('vue-tel-input').default);
