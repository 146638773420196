<template>
    <div>
        <div class="white-box mb-0">
            <h3 class="mb-2">Primary Phone Number</h3>
            
            <div class="form-group mt-5">
                <label for="primaryPhoneNumber.phoneNumber">Primary number</label>
                <vue-tel-input :key="componentKey" 
                    mode="national" 
                    v-model="primaryPhoneNumber.phoneNumber" 
                    :defaultCountry="String(primaryPhoneNumber.phoneCode)" 
                    :dropdownOptions="{showFlags: true, showSearchBox: true, showDialCodeInSelection: true, showDialCodeInList: true}"
                    :autoFormat="false"
                    v-bind:class="{'is-invalid': validation.invalid.UpdatedPhoneNumber || this.validation.invalid.NewPhoneCode || this.validation.invalid.NewPhoneNumber }"
                    v-on:focus="clearValidation()"
                    @country-changed="onCountrySelect"></vue-tel-input>
                <div class="invalid-feedback" v-if="this.validation.invalid.UpdatedPhoneNumber">
                    <p class="mb-0" v-for="error in this.validation.invalid.UpdatedPhoneNumber">{{ error }}</p>
                </div>
                <div class="invalid-feedback" v-if="this.validation.invalid.NewPhoneCode">
                    <p class="mb-0" v-for="error in this.validation.invalid.NewPhoneCode">{{ error }}</p>
                </div>
                <div class="invalid-feedback" v-if="this.validation.invalid.NewPhoneNumber">
                    <p class="mb-0" v-for="error in this.validation.invalid.NewPhoneNumber">{{ error }}</p>
                </div>

                <div class="d-flex flex-row-reverse p-2">
                    <vue-button-spinner v-if="primaryPhoneNumber.oldPhoneNumber !== undefined"
                        :is-loading="isLoading" 
                        :disabled="isLoading">
                            <span type="button" class="text-primary"
                                v-bind:class="{'is-pointer-none': isLoading }"
                                @click="editPhoneNumber(primaryPhoneNumber.phoneNumber, primaryPhoneNumber.oldPhoneCode, primaryPhoneNumber.oldPhoneNumber)">Save</span>
                    </vue-button-spinner>

                    <vue-button-spinner v-if="toString(primaryPhoneNumber.phoneNumber).length > 0 && primaryPhoneNumber.oldPhoneNumber === undefined"
                        :is-loading="isLoading" 
                        :disabled="isLoading">
                            <span type="button" class="text-primary"
                                v-bind:class="{'is-pointer-none': isLoading }"
                                @click="createNewPhone(primaryPhoneNumber.phoneNumber)">Save</span>
                    </vue-button-spinner>
                </div>
            </div>
        </div>
        <div v-if="hasEnteredPhoneNumbers">
            <div class="white-box mb-0">
                <h3 class="mb-2">Alt Phone Numbers</h3>
                <p class="mb-5">Other email adressess you  can use to sign in or to recover your account</p>
                <div v-for="(phone, index) in this.secondaryPhoneNumbers" :key="index">
                    <phone-component :key="componentKey"
                        :primaryPhoneNumber="primaryPhoneNumber"
                        :secondaryPhoneNumbers="secondaryPhoneNumbers"
                        :phone="phone"
                        :index="index"
                        :allPhones="allPhones"
                        :addNewPhone="addNewPhone"
                        :editPhone="editPhone"
                        :deletePhone="deletePhone"
                        :changeMainPhone="changeMainPhone"
                        :stogeryToken="stogeryToken"
                        :returnUrl="returnUrl"
                        @updatePhones="updatePhones"></phone-component>
                </div>
            </div>

            <div class="btn-add-wrapper">
                <mdb-btn type="button" class="btn--primary btn-add w-100 mb-5" rounded @click="addNewRow">+ Add Phone Number</mdb-btn>
            </div>
        </div>
        
        <return-back-modal ref="goBackModalComponent" 
            @goBack="sendTitle"></return-back-modal>
    </div>
</template>

<script>
import { ref } from 'vue';
import returnBackModal from '../../return-back-modal.vue';
import PhoneComponent from '../../personalInfo/components/phone.vue';
import { post } from '../../../services/http-common.js';
import VueButtonSpinner from 'vue-button-spinner';

export default {
    components: { returnBackModal, PhoneComponent, VueButtonSpinner },
    name: 'PersonalPhoneComponent',
    data() {
        return {
            primaryPhoneNumber: String,
            secondaryPhoneNumbers: Array,
            primaryPhoneCode: String,
            componentKey: 0,
            phoneCode: String,
            isLoading: false,
            validation: {
                invalid: {},
                valid: {},
            },
            hasEnteredPhoneNumbers: true
        }
    },
    props: {
        allPhones: ref(Array),
        addNewPhone: ref(String),
        editPhone: ref(String),
        deletePhone: ref(String),
        changeMainPhone: ref(String),
        stogeryToken: ref(String),
        returnUrl: ref(String),
        phone: ref(Object),
    },
    created() {
        if (typeof(this.allPhones) === 'object') {
            this.primaryPhoneNumber = this.allPhones.find(phone => phone.isMain)
            this.secondaryPhoneNumbers = this.allPhones.filter(phone => phone.isMain === false)
        } else if(typeof(this.allPhones) === 'string') {
            this.hasEnteredPhoneNumbers = false;
        }
    },
    watch: {
        // On back button show Security 
        allPhones(allPhones) {
            this.primaryPhoneNumber = this.allPhones.find(phone => phone.isMain)
            this.secondaryPhoneNumbers = this.allPhones.filter(phone => phone.isMain === false)
            this.componentKey += 1; // Use it to force Vue to re-render
        }
    },
    methods: {
        clearValidation: function() {
            this.validation.invalid.UpdatedPhoneNumber = '';
            this.$forceUpdate();
        },
        sendTitle() {
            this.$emit("showComponent", 'Personal Info');
        },
        addNewRow() {
            this.secondaryPhoneNumbers.push(
                {
                    isMain: false,
                    phoneCode: 'US',
                    phoneNumber: null,
                    printPhone: null
                }
            )
        },
        createNewPhone(phoneNumber) {
            let data = new FormData();
            data.append('NewPhoneCode', this.phoneCode);
            data.append('NewPhoneNumber', phoneNumber);
            data.append("IsMainNew", true);
            data.append("ReturnUrl", this.returnUrl);
            this.isLoading = true;

            post(this.addNewPhone, data, this.stogeryToken)
                .then((response) => {
                    this.resetValidation();
                    this.isLoading = false;
                    this.updatePhones(response.data, "CREATE_MAIN");
                })
                .catch((error) => {
                    this.isLoading = false;

                    if (error.response) {
                        this.validation.invalid = error.response.data;
                    // Request made and server responded
                    } else if (error.request) {
                    // The request was made but no response was received
                        console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                })
        },
        onCountrySelect({dialCode, iso2, name}) {
            this.phoneCode = iso2;
        },
        editPhoneNumber(newPhoneNumber ,oldPhoneCode, oldPhoneNumber) {
            let data = new FormData();
            data.append('OldPhoneCode', oldPhoneCode);
            data.append('OldPhoneNumber', oldPhoneNumber);
            data.append('UpdatedPhoneCode', this.phoneCode);
            data.append('UpdatedPhoneNumber', newPhoneNumber);
            data.append("ReturnUrl", this.returnUrl);
            this.isLoading = true;

            post(this.editPhone, data, this.stogeryToken)
                .then((response) => {
                    this.resetValidation()
                    this.isLoading = false;

                    this.primaryPhoneNumber = response.data;
                    this.primaryPhoneNumber.oldPhoneCode = oldPhoneCode;
                    this.primaryPhoneNumber.oldPhoneNumber = oldPhoneNumber;
                    
                    this.updatePhones(this.primaryPhoneNumber, "UPDATE");
                    
                    this.$toast.success('Phone updated.', {
                        // optional options Object
                    })
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$toast.error("The phone couldn't be updated.", {
                        // optional options Object
                    })
                    
                    if (error.response) {
                        this.validation.invalid = error.response.data;
                    // Request made and server responded
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                })
        }, 
        updatePhones(data, eventType) {
            this.$emit("updatePhones", {
              'phone' : data,
              'eventType' : eventType
            });
        },
        resetValidation() {
            this.validation = {
                invalid: {},
                valid: {},
            }
        }
    }
}
</script>
