<template>
    <div class="product product--returns">
        <a :href="link" class="product__content text-center">
            <i class="icon"
                :class="icon"></i>
            <h3>{{ title }}</h3>
        </a>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: 'ShortcutComponent',
    data(){ 
        return {
            link: this.linkProp,
            title: this.titleProp,
            icon: this.iconProp
        }   
    },
    props: {
        linkProp: ref(String),
        titleProp: ref(String),
        iconProp: ref(String),
    }
}
</script>

<style lang="scss" scoped>
    .icon {
      @include themify($themes) {
        color: themed('sidebarActiveItem');
      }
    }
</style>