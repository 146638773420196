<template>
  <section class="wrapper wrapper-enroll col-lg-6 d-flex flex-column align-items-center justify-content-center">
    <div id="step1" v-if="activeSection === 1">
      <div class="col-xl-10 offset-xl-1 d-flex flex-column align-items-center justify-content-center">
        <h2 class="form-title font-weight-bold">Confirm Your External Data</h2>
        <div class="w-100">
          <div class="form-group">
            <label>First Name*</label>
            <input type="text" :disabled="btnForceRequest === true" v-bind:class="{'is-invalid': validation.invalid.FirstName }" class="form-control" v-on:input="validation.invalid.FirstName = '';"
                   placeholder="First Name"
                   v-model="firstName"
                   v-on:focus="clearValidation('firstName')">
            <div class="invalid-feedback" v-for="error in validation.invalid.FirstName">{{ error }}</div>
          </div>

          <div class="form-group">
            <label>Last Name*</label>
            <input type="text" :disabled="btnForceRequest === true" v-bind:class="{'is-invalid': validation.invalid.LastName }" class="form-control" v-on:input="validation.invalid.LastName = '';"
                   placeholder="Last Name"
                   v-model="lastName"
                   v-on:focus="clearValidation('lastName')">
            <div class="invalid-feedback" v-for="error in validation.invalid.LastName">{{ error }}</div>
          </div>

          <div class="form-group">
            <label>Email*</label>
            <input type="text" :disabled="btnForceRequest===true" v-bind:class="{'is-invalid': validation.invalid.Email }" class="form-control" v-on:input="validation.invalid.Email = '';"
                   placeholder="Email" v-model="email"
                   v-on:focus="clearValidation('email')">
            <div class="invalid-feedback" v-for="error in validation.invalid.Email">{{ error }}</div>
          </div>

          <button type="button" :disabled="btnForceRequest === true" name="button" class="btn btn--primary m-0 w-100" @click="onSave">Apply</button>
        </div>
      </div>
    </div>

    <div class="enroll-step" id="step2" v-if="activeSection === 2">
      <h2 class="form-title font-weight-bold">Confirm your email:</h2>
      <h4 class="text-center">Enter the code from your email</h4>
      <p class="info-text mb-5 text-center">Please confirm your email ownership.<br> Enter the 8-digit code sent to <span class="font-weight-bold">{{ this.emailWithMask }}</span> below </p>

      <div class="form-group">
        <input :disabled="btnForceRequest === true" type="text" id="SecurityCode" v-bind:class="{'is-invalid': validation.invalid.SecurityCode }" v-model="securityCode" class="form-control code-input"
               placeholder="XXXX-XXXX">
        <div class="invalid-feedback" v-for="error in validation.invalid.SecurityCode">{{ error }}</div>
      </div>

      <a @click="resendSecurityCode" class="resend-email d-block mt-3 mb-0 text-right">Resend confirmation email</a>

      <div class="btns-nav mt-5 pt-4 d-flex align-items-center flex-wrap">
        <button type="button" @click="goToStep(1)" class="btn--back m-0 p-0 text-left"><img src="../assets/img/arrow-left.svg" alt="" class="mr-2"> Go Back</button>
        <button type="button" :disabled="btnForceRequest === true" @click="onConfirmSecurityCode()" class="btn btn--primary m-0">Continue <img src="@/assets/img/arrow-right.svg" alt="" class="ml-2">
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {ref} from "vue";
import {post} from '../services/http-common.js';

export default {
  name: "external-signup",
  props: {
    forgeryToken: ref(String),
    modelReturnUrl: ref(String),
    modelFirstName: ref(String),
    modelLastName: ref(String),
    modelEmail: ref(String),
    pathConfirmLoginData: ref(String),
    pathResendSecurityCodePath: ref(String),
    pathConfirmSecurityCodePath: ref(String),
    confirmAggregateRootPath: ref(String)
  },
  mounted() {
    this.forgeryHeaderToken = this.forgeryToken;
    this.returnUrl = this.modelReturnUrl;
    this.formConfirmLoginDataPath = this.pathConfirmLoginData;
    this.firstName = this.modelFirstName;
    this.lastName = this.modelLastName;
    this.email = this.modelEmail;
    this.formResendSecurityCodePath = this.pathResendSecurityCodePath;
    this.formConfirmSecurityCodePath = this.pathConfirmSecurityCodePath;
    this.formConfirmAggregateRootPath = this.confirmAggregateRootPath;
  },
  data() {
    return {
      formConfirmLoginDataPath: "",
      formResendSecurityCodePath: "",
      formConfirmSecurityCodePath: "",
      formConfirmAggregateRootPath: "",

      activeSection: 1,
      btnForceRequest: false,
      forgeryHeaderToken: '',
      returnUrl: '',

      firstName: "",
      lastName: "",
      email: "",

      emailWithMask: "",
      securityCode: "",
      aggregateRootId: "",
      hash: "",
      aggregateRootIsNeedFromConfirmation: false,

      validation: {
        invalid: {},
        valid: {}
      }
    }
  },
  methods: {
    goToStep(step) {
      this.activeSection = step;
    },
    clearValidation(field) {
      this.validation.valid[field] = '';
      this.validation.invalid[field] = '';
      this.$forceUpdate();
    },
    onSave() {
      this.btnForceRequest = true;
      let data = new FormData();
      data.append("returnUrl", this.returnUrl);
      data.append("firstName", this.firstName);
      data.append("lastName", this.lastName);
      data.append("email", this.email);

      post(this.formConfirmLoginDataPath, data, this.forgeryHeaderToken)
          .then((response) => {
            if (response.status === 200) {
              this.emailWithMask = response.data.emailWithMask;
              this.securityCode = "";
              this.aggregateRootId = response.data.aggregateRootId;
              this.hash = response.data.hash;
              this.returnUrl = response.data.returnUrl;
              this.aggregateRootIsNeedFromConfirmation = response.data.aggregateRootIsNeedFromConfirmation;
              this.goToStep(2);
            }
            this.btnForceRequest = false;
          })
          .catch((error) => {
            this.btnForceRequest = false;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
              // Request made and server responded
              if (error.response.status >= 300 && error.response.status <= 309) {
                this.$toast.warning("Redirecting...")
                window.location.href = error.response.data.Location[0];
              }
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },

    resendSecurityCode() {
      this.btnForceRequest = true;

      let data = new FormData();
      data.append("ReturnUrl", this.returnUrl);
      data.append("Hash", this.hash);
      data.append("AggregateRootId", this.aggregateRootId);

      post(this.formResendSecurityCodePath, data, this.forgeryHeaderToken)
          .then((response) => {
            this.$toast.success('The security code has been sent successfully', {})
            this.btnForceRequest = false;
            this.hash = response.data.hash;
            this.returnUrl = response.data.returnUrl;
          })
          .catch((error) => {
            this.btnForceRequest = false;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
              // Request made and server responded
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },
    onConfirmSecurityCode() {
      this.btnForceRequest = true;

      let data = new FormData();
      data.append("ReturnUrl", this.returnUrl);
      data.append("Hash", this.hash);
      data.append("AggregateRootId", this.aggregateRootId);
      data.append("SecurityCode", this.securityCode);

      let headers = {
        "response_location_status": "ok",
        "token": this.forgeryHeaderToken
      };
      post(this.formConfirmSecurityCodePath, data, this.forgeryHeaderToken)
          .then((response) => {
            if (response.status === 200) {
              //Process to confirm the aggregate 
              this.$toast.warning("Processing...");

              //Is Need to confirm the aggregate
              if (this.aggregateRootIsNeedFromConfirmation) {

                let dataAggregate = new FormData();
                dataAggregate.append("ReturnUrl", this.returnUrl);
                dataAggregate.append("Hash", this.hash);
                dataAggregate.append("AggregateRootId", this.aggregateRootId);

                post(this.formConfirmAggregateRootPath, dataAggregate, headers)
                    .then((aggregateResponse) => {
                      if (aggregateResponse.status === 200) {
                        this.$toast.warning("Redirecting...")
                        window.location.href = response.data.path;
                      }

                    }).catch((error) => {
                  this.btnForceRequest = false;
                  if (error.response) {
                    // Request made and server responded
                    this.validation.invalid = error.response.data;
                    // Request made and server responded
                  } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                  }
                });
                return;
              }
              //No need from confirm the aggregate and just apply redirection
              this.$toast.warning("Redirecting...")
              window.location.href = response.data.path;
              this.btnForceRequest = false;
            }

          })
          .catch((error) => {
            this.btnForceRequest = false;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
              // Request made and server responded
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    }
  }
}
</script>

