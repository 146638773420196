<template>
    <div class="d-flex justify-content-between">
        <div class="form-group w-100">
            <div class="d-flex justify-content-between align-items-center">
                <label for="phoneNumber">Alt Number {{ index + 1 }}</label>
                <mdb-btn v-if="phone.oldPhoneNumber !== undefined" type="button" class="btn--icon p-2 m-0" 
                    @click="deletePhoneNumberRow(index)"><i class="icon icon-bin fs_20 text-danger"></i></mdb-btn>
            </div>
            <div role="alert" class="alert alert-danger" v-if="this.validation.invalid.UpdatedPhoneNumber">
                <h6 class="pl-4 text-center">
                    <i class="icon icon-issue align-middle mr-1"></i>
                    <strong>Error</strong>
                </h6>
                <p class="mb-0 text-center text-white" v-for="error in validation.invalid.UpdatedPhoneNumber">
                    {{ error }}
                </p>
            </div>
            <vue-tel-input mode="national" data-tab-url="foo"
                v-model="phone.phoneNumber"
                :id="index" 
                :defaultCountry="String(phone.phoneCode)"
                :autoDefaultCountry="true"
                :dropdownOptions="{showFlags: true, showSearchBox: true, showDialCodeInSelection: true, showDialCodeInList: false}" 
                :inputOptions="{autocomplete: false}"
                @country-changed="onCountrySelect"
                @input="onPhoneChange"
                :autoFormat="false"
                v-bind:class="{'is-invalid': validation.invalid.NewPhoneNumber || validation.invalid.NewPhoneCode }"
                v-on:focus="clearValidation()"
                ></vue-tel-input>
                
                <div class="invalid-feedback" v-if="this.validation.invalid.NewPhoneCode">
                    <p class="mb-0" v-for="error in validation.invalid.NewPhoneCode">{{ error }}</p>
                </div>
                <div class="invalid-feedback" v-if="this.validation.invalid.NewPhoneNumber">
                    <p class="mb-0" v-for="error in validation.invalid.NewPhoneNumber">{{ error }}</p>
                </div>

            <div class="d-flex justify-content-between p-2">
                <span :class="{ invisible: phone.oldPhoneNumber === undefined }" type="button" class="font-weight-bold"
                    @click="makeMainNumberRow(index)">Make Primary</span>

                <vue-button-spinner v-if="phone.oldPhoneNumber !== undefined"
                    :is-loading="isLoading" 
                    :disabled="isLoading">
                    <span type="button" class="text-primary" 
                        v-bind:class="{'is-pointer-none': isLoading }"
                        @click="editPhoneNumber(phone.phoneNumber, phone.oldPhoneCode, phone.oldPhoneNumber)">Save</span>
                </vue-button-spinner>

                 <vue-button-spinner v-if="phone.phoneNumber !== null && toString(phone.phoneNumber).length > 0 && phone.oldPhoneNumber === undefined"
                    :is-loading="isLoading" 
                    :disabled="isLoading">
                    <span type="button" class="text-primary"
                        v-bind:class="{'is-pointer-none': isLoading }"
                        @click="createNewPhone(phone.phoneNumber)">Save</span>
                 </vue-button-spinner>
            </div>
        </div>

        <delete-phone-modal ref="deletePhoneModalComponent" 
            @deletePhone="deletePhoneNumberRequest"></delete-phone-modal>

        <update-phone-primary-modal ref="updatePhonePrimaryModalComponent" 
            @updatePhonePrimary="changeMainPhoneRequest"></update-phone-primary-modal>
    </div>
</template>

<script>
import { ref } from 'vue';
import deletePhoneModal from '../../delete-phone-modal.vue';
import updatePhonePrimaryModal from '../../update-phone-primary-modal.vue';
import { post } from '../../../services/http-common.js';
import VueButtonSpinner from 'vue-button-spinner';

export default {
    components: { deletePhoneModal, updatePhonePrimaryModal, VueButtonSpinner },
    name: 'PhoneComponent',
    data() {
        return {
            phoneCode: String,
            phoneNumber: '',
            deletePhoneIndex: Number,
            updatePhonePrimaryIndex: Number,
            isLoading: false,
            validation: {
                invalid: {},
                valid: {},
            },
        }
    },
    props: {
        index: Number,
        phone: ref(Object),
        primaryPhoneNumber: ref(Object),
        secondaryPhoneNumbers: ref(Array),
        allPhones: ref(Array),
        addNewPhone: ref(String),
        editPhone: ref(String),
        deletePhone: ref(String),
        changeMainPhone: ref(String),
        stogeryToken: ref(String),
        returnUrl: ref(String)
    },
    methods: {
        clearValidation: function() {
            this.validation.invalid.NewPhoneNumber = '';
            this.$forceUpdate();
        },
        toString(number) {
            return String(number)
        },
        
        onCountrySelect({dialCode, iso2, name}) {
            this.phoneCode = iso2;
            this.validation.invalid.NewPhoneCode = '';
        },
        onPhoneChange(number, phoneObject) {
           
        },
        createNewPhone(phoneNumber) {
            let data = new FormData();
            data.append('NewPhoneCode', this.phoneCode);
            data.append('NewPhoneNumber', phoneNumber);
            data.append("IsMainNew", true);
            data.append("ReturnUrl", this.returnUrl);
            this.isLoading = true;

            post(this.addNewPhone, data, this.stogeryToken)
                .then((response) => {
                    this.on(response.data, "CREATE");
                    this.isLoading = false;
                    this.$toast.success('Phone saved.', {
                        // optional options Object
                    })
                })
                .catch((error) => {
                    this.isLoading = false
                    this.$toast.error("The phone couldn't be saved.", {
                        // optional options Object
                    })

                    if (error.response) {
                        this.validation.invalid = error.response.data;
                    // Request made and server responded
                    } else if (error.request) {
                    // The request was made but no response was received
                        console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                })
        },
        // TODO: emit this method in parrent component
        editPhoneNumber(newPhoneNumber ,oldPhoneCode, oldPhoneNumber) {
            let data = new FormData();
            data.append('OldPhoneCode', oldPhoneCode);
            data.append('OldPhoneNumber', oldPhoneNumber);
            data.append('UpdatedPhoneCode', this.phoneCode);
            data.append('UpdatedPhoneNumber', newPhoneNumber);
            data.append("ReturnUrl", this.returnUrl);
            this.isLoading = true

            post(this.editPhone, data, this.stogeryToken)
                .then((response) => {
                  response.data.oldPhoneCode = oldPhoneCode;
                  response.data.oldPhoneNumber = oldPhoneNumber;
                  
                  this.on(response.data, "UPDATE");
                  this.isLoading = false
                    this.$toast.success('Phone updated.', {
                        // optional options Object
                    })
                })
                .catch((error) => {
                    this.isLoading = false
                    this.$toast.error("The phone couldn't be updated.", {
                        // optional options Object
                    })

                    if (error.response) {
                        this.validation.invalid = error.response.data;
                    // Request made and server responded
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                })
        },
        deletePhoneNumberRow(index) {
            this.deletePhoneIndex = index;
            this.$refs.deletePhoneModalComponent.showModal(true);
        },
        deletePhoneNumberRequest() {
            let phoneCode = this.secondaryPhoneNumbers[this.deletePhoneIndex].phoneCode;
            let phoneNumber = this.secondaryPhoneNumbers[this.deletePhoneIndex].phoneNumber;

            let data = new FormData();
            data.append('PhoneCodeForDelete', phoneCode);
            data.append('PhoneNumberForDelete', phoneNumber);
            data.append("ReturnUrl", this.returnUrl);

            post(this.deletePhone, data, this.stogeryToken)
                .then((response) => {
                  this.on(response.data, "DELETE");
                  this.$toast.success('Phone deleted.', {
                        // optional options Object
                    })
                })
                .catch((error) => {
                    this.$toast.error("The phone couldn't be deleted.", {
                        // optional options Object
                    })

                    if (error.response) {
                        console.log(error.response)
                    // Request made and server responded
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                })
        },
        makeMainNumberRow(index) {
            this.updatePhonePrimaryIndex = index;
            this.$refs.updatePhonePrimaryModalComponent.showModal(true);
        },
        changeMainPhoneRequest() {
            let phoneRecord = this.secondaryPhoneNumbers[this.updatePhonePrimaryIndex]; 
            let phoneCode = phoneRecord.phoneCode;
            let phoneNumber = phoneRecord.phoneNumber;
            
            let data = new FormData();
            data.append('NewMainPhoneCode', phoneCode);
            data.append('NewMainPhoneNumber', phoneNumber);
            data.append("ReturnUrl", this.returnUrl);

            post(this.changeMainPhone, data, this.stogeryToken)
                .then((response) => {
                    this.on(phoneRecord, "CHANGE_MAIN")

                    this.$toast.success('Phone updated.', {
                        // optional options Object
                    })
                })
                .catch((error) => {
                    this.$toast.error("The phone couldn't be updated.", {
                        // optional options Object
                    })
                    
                    if (error.response) {
                        console.log(error.response)
                    // Request made and server responded
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                })
        },
        
        on(data, eventType) {
          this.$emit("updatePhones", data, eventType);
        }
    }
}
</script>

<style>
    .invisible {
        visibility: hidden;
    }
</style>
