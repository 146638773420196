<template>
  <div>
    <mdb-modal :show="modal" @close="modal = false" centered >
      <mdb-modal-body class="text-center py-4 mt-2 px-5">
         <h3>Step 1/2</h3>
        <h2 class="form-title font-weight-bold">Verify your new email:</h2>
        <p class="info-text text-center">Enter the 8-digit code sent to <span class="font-weight-bold">{{ newEmail }}</span> below </p>
        <p class="info-text mb-5 text-center">Did not receive an email? 
            <span type="button" class="text-primary" 
                @click="onResend">Resend Code</span>
        </p>

        <div class="form-group">
            <input type="text" id="securityCode"  class="form-control code-input" placeholder="XXXX-XXXX"
                v-model="securityCode"
                v-bind:class="{'is-invalid': validation.invalid.Code}">
            <div class="invalid-feedback"  v-for="(error, index) in validation.invalid.Code" v-bind:key="index">{{ error }}</div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer class="d-flex justify-content-center pt-0 border-0">
        <mdb-btn class="btn--back" 
            @click.native="modal = false">Cancel</mdb-btn>
        
        <vue-button-spinner
            :is-loading="isLoading" 
            :disabled="isLoading">
            <mdb-btn class="btn--primary" 
                :disabled="isLoading"
                @click="onVerifyNewEmail">Verify</mdb-btn>
        </vue-button-spinner>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal :show="oldEmailmodal" @close="oldEmailmodal = false" centered >
      <mdb-modal-body class="text-center py-4 mt-2 px-5">
         <h3>Step 2/2</h3>
        <h2 class="form-title font-weight-bold">Verify your old email:</h2>
        <p class="info-text text-center">Enter the 8-digit code sent to <span class="font-weight-bold">{{ primaryEmail }}</span> below </p>
        <p class="info-text mb-5 text-center">Did not receive an email? 
            <span type="button" class="text-primary" 
                @click="onResend">Resend Code</span>
        </p>

        <div class="form-group">
            <input type="text" id="securityCodeOld" class="form-control code-input" placeholder="XXXX-XXXX"
                v-model="securityCodeOld"
                v-bind:class="{'is-invalid': validation.invalid.Code }">
            <div class="invalid-feedback"  v-for="(error, index) in validation.invalid.Code" v-bind:key="index">{{ error }}</div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer class="d-flex justify-content-center pt-0 border-0">
        <mdb-btn class="btn--back" 
            @click.native="oldEmailmodal = false">Cancel</mdb-btn>

        <vue-button-spinner
            :is-loading="isLoading" 
            :disabled="isLoading">
            <mdb-btn class="btn--primary" 
                :disabled="isLoading"
                @click="onVerifyOldEmailByEmail">Verify</mdb-btn>
        </vue-button-spinner>
      </mdb-modal-footer>
    </mdb-modal>

     <mdb-modal :show="mfaOldEmailmodal" @close="mfaOldEmailmodal = false" centered >
      <mdb-modal-body class="text-center py-4 mt-2 px-5">
         <h3>Step 2/2</h3>
        <h2 class="form-title font-weight-bold">Two-Factor Verification</h2>
        <p class="info-text text-center">Enter the 8-digit code found in your <span class="font-weight-bold">Authenticator</span> app</p>
        
        <div class="form-group">
            <input type="text" id="mfaSecurityCodeOld" class="form-control code-input" placeholder="XXX-XXX"
                v-model="mfaSecurityCodeOld" 
                v-bind:class="{'is-invalid': validation.invalid.Code }">
            <div class="invalid-feedback"  v-for="(error, index) in validation.invalid.Code" v-bind:key="index">{{ error }}</div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer class="d-flex justify-content-center pt-0 border-0">
        <mdb-btn class="btn--back" 
            @click.native="mfaOldEmailmodal  = false">Cancel</mdb-btn>

        <vue-button-spinner
            :is-loading="isLoading" 
            :disabled="isLoading">
            <mdb-btn class="btn--primary" 
                :disabled="isLoading"
                @click="onVerifyOldEmailByMfa">Verify</mdb-btn>
        </vue-button-spinner>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal :show="successModal" @close="modal = false" centered >
      <mdb-modal-body class="text-center py-4 mt-2 px-5">
        <h3 class="font-weight-bold">Your email has been changed</h3>
      </mdb-modal-body>
      <mdb-modal-footer class="d-flex justify-content-center pt-0 border-0">
        <mdb-btn class="btn--primary" rounded 
            @click.native="successModal = false">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
    import { ref } from 'vue';
    import { post } from '../services/http-common.js';
    import {
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn
    } from 'mdbvue';
    import VueButtonSpinner from 'vue-button-spinner';

    export default {
        components: {
            mdbModal,
            mdbModalHeader,
            mdbModalTitle,
            mdbModalBody,
            mdbModalFooter,
            mdbBtn,
            VueButtonSpinner
        },
        name: "VerifyModal",
        data() {
            return {
                securityCode: '',
                securityCodeOld: '',
                mfaSecurityCodeOld: '',
                modal: false,
                oldEmailmodal: false,
                mfaOldEmailmodal: false,
                successModal: false,
                isLoading: false,
                emailVerifyId: '',
                validation: {
                    invalid: {},
                    valid: {},
                }
            }
        },
        props: {
            verifyId: ref(String),
            returnUrl: ref(String),
            stogeryToken: ref(String),
            newEmail: ref(String),
            primaryEmail: ref(String)
        },
         watch:  {
             verifyId() {
                 this.emailVerifyId = this.verifyId
             }
        },
        methods: {
            showModal (data) {
                if(data) {
                    this.modal = true;
                }
            },
            onVerifyNewEmail() {
                let data = new FormData();
                data.append("ReturnUrl", this.returnUrl);
                data.append("Code", this.securityCode)
                data.append("VerifyId", this.emailVerifyId)
                this.isLoading = true;

                post('/Account/Verify/EmailCode', data, this.stogeryToken)
                    .then((response) => {
                        this.emailVerifyId = response.data.verifyId;
                        this.returnUrl = response.data.returnUrl;

                        let data = new FormData();
                        data.append("ReturnUrl", this.returnUrl);
                        data.append("VerifyId", this.emailVerifyId)
                        
                        this.resetValidation()
                        post('/Account/Profile/EditEmail/ConfirmNewEmail', data, this.stogeryToken)
                            .then((response) => {
                                this.emailVerifyId = response.data.verifyId;
                                this.returnUrl = response.data.returnUrl;

                                this.modal = false

                                this.isLoading = false;
                                this.$toast.success('Verification Successful.', {
                                    // optional options Object
                                })

                                if(response.data.providerTypeKey === '_EMAIL_VERIFICATION_TYPE') {
                                    this.oldEmailmodal = true
                                }else if(response.data.providerTypeKey === '_GOOGLE_AUTH_VERIFICATION_TYPE') {
                                    this.mfaOldEmailmodal = true
                                }
                            })
                          .catch((error) => {
                                this.isLoading = false;
                                this.$toast.error("Verification Failed.", {
                                    // optional options Object
                                })

                                if (error.response) {
                                    console.log(error.response)
                                // Request made and server responded
                                } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                                } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log('Error', error.message);
                                }
                            })
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error.response) {
                            this.validation.invalid = error.response.data;
                            console.log(error.response)
                        // Request made and server responded
                        } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        }
                    })
            },
            onResend() {
                let data = new FormData();
                data.append("ReturnUrl", this.returnUrl);
                data.append("VerifyId", this.emailVerifyId)

                post('/Account/Verify/EmailCode/Resend', data, this.stogeryToken)
                    .then((response) => {
                        this.emailVerifyId = response.data.verifyId;
                        this.returnUrl = response.data.returnUrl;

                        this.$toast.success('Code resend successfully.', {
                            // optional options Object
                        })
                    })
                    .catch((error) => {
                        this.$toast.error("Code resend failed.", {
                            // optional options Object
                        })

                        if (error.response) {
                            this.validation.invalid = error.response.data;
                            console.log(error.response)
                        // Request made and server responded
                        } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        }
                    })
            },
            onVerifyOldEmailByEmail() {
                let data = new FormData();
                data.append("ReturnUrl", this.returnUrl);
                data.append("Code", this.securityCodeOld)
                data.append("VerifyId", this.emailVerifyId)

                this.isLoading = true;
                
                post('/Account/Verify/EmailCode', data, this.stogeryToken)
                    .then((response) => {
                        this.emailVerifyId = response.data.verifyId;
                        this.returnUrl = response.data.returnUrl;

                        let data = new FormData();
                        data.append("ReturnUrl", this.returnUrl);
                        data.append("VerifyId", this.emailVerifyId)
                        
                        this.resetValidation()

                        post('/Account/Profile/EditEmail/ConfirmCurrentEmail', data, this.stogeryToken)
                            .then((response) => {
                                this.emailVerifyId = response.data.verifyId;
                                this.returnUrl = response.data.returnUrl;
                                
                                let data = new FormData();
                                data.append("ReturnUrl", this.returnUrl);
                                data.append("VerifyId", this.emailVerifyId)
                                
                                post('/Account/Profile/ChangeEmail', data, this.stogeryToken)
                                    .then((response) => {
                                         this.$emit("updateEmail", this.newEmail)
                                         this.securityCode = ''
                                         this.securityCodeOld = ''
                                        
                                        this.oldEmailmodal = false
                                        this.successModal = true

                                        const userEmail = document.getElementById("user-email");
                                        userEmail.innerText = this.newEmail

                                        this.isLoading = false;
                                        this.$toast.success('Verification Successful.', {
                                            // optional options Object
                                        })
                                    })
                                    .catch((error) => {
                                        this.isLoading = false;
                                        this.$toast.error("Verification Failed.", {
                                            // optional options Object
                                        })

                                        if (error.response) {
                                            console.log(error.response)
                                        // Request made and server responded
                                        } else if (error.request) {
                                        // The request was made but no response was received
                                        console.log(error.request);
                                        } else {
                                        // Something happened in setting up the request that triggered an Error
                                        console.log('Error', error.message);
                                        }
                                    })
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                if (error.response) {
                                    console.log(error.response)
                                // Request made and server responded
                                } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                                } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log('Error', error.message);
                                }
                            })
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.validation.invalid = error.response.data;
                            console.log(error.response)
                        // Request made and server responded
                        } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        }
                    })
            },
            onVerifyOldEmailByMfa() {
                let data = new FormData();
                data.append("ReturnUrl", this.returnUrl);
                data.append("VerifyId", this.emailVerifyId);
                data.append("Code", this.mfaSecurityCodeOld);
                this.isLoading = true;

                post('/Account/Verify/GoogleAuthentication', data, this.stogeryToken)
                    .then((response) => {
                        this.emailVerifyId = response.data.verifyId;
                        this.returnUrl = response.data.returnUrl;
                        
                        let data = new FormData();
                        data.append("ReturnUrl", this.returnUrl);
                        data.append("VerifyId", this.emailVerifyId)

                        this.resetValidation()
                        
                        post('/Account/Profile/EditEmail/ConfirmCurrentEmail', data, this.stogeryToken)
                            .then((response) => {
                                this.emailVerifyId = response.data.verifyId;
                                this.returnUrl = response.data.returnUrl;
                                
                                let data = new FormData();
                                data.append("ReturnUrl", this.returnUrl);
                                data.append("VerifyId", this.emailVerifyId)
                                
                                post('/Account/Profile/ChangeEmail', data, this.stogeryToken)
                                    .then((response) => {
                                        this.$emit("updateEmail", this.newEmail)
                                        this.securityCode = ''
                                        this.mfaSecurityCodeOld = ''
                                        
                                        this.mfaOldEmailmodal = false
                                        this.successModal = true

                                        const userEmail = document.getElementById("user-email");
                                        userEmail.innerText = this.newEmail
                                        
                                        this.isLoading = false;
                                        this.$toast.success('Verification Successful.', {
                                            // optional options Object
                                        })
                                    })
                                    .catch((error) => {
                                        this.isLoading = false;
                                        this.$toast.error("Verification Failed.", {
                                            // optional options Object
                                        })

                                        if (error.response) {
                                            console.log(error.response)
                                        // Request made and server responded
                                        } else if (error.request) {
                                        // The request was made but no response was received
                                        console.log(error.request);
                                        } else {
                                        // Something happened in setting up the request that triggered an Error
                                        console.log('Error', error.message);
                                        }
                                    })
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                if (error.response) {
                                    console.log(error.response)
                                // Request made and server responded
                                } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                                } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log('Error', error.message);
                                }
                            })
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.validation.invalid = error.response.data;
                            console.log(this.validation.invalid);
                        // Request made and server responded
                        } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        }
                    })
            },
            resetValidation() {
                this.validation = {
                    invalid: {},
                    valid: {},
                }
            }
        }
    }
</script>