<template>
  <div class="container-fluid body-container white-box__container">
    <page-title 
      :title=title 
      :showBtn=showBtn 
      parentSection="Personal Info" 
      @sendBack="setComponent"></page-title>

    <basic-info-component v-if="this.title !== 'Email' && this.title !== 'Erasure request' && this.title !== 'Phone'" 
      @changeTitle="changeTitle" 
      :returnPersonalInfo="returnPersonalInfo"
      :abbrProp = abbr 
      :nameProp = name 
      :firstNameProp = first 
      :lastNameProp = last 
      :saveNamesPathProp = savenames 
      :stogeryTokenNameProp = stogeryname 
      :stogeryTokenProp = stogerytoken></basic-info-component>

    <contact-details-component v-if="this.title !== 'Name' && this.title !== 'Erasure request'" 
      @changeTitle="changeTitle" 
      :returnPersonalInfo="returnPersonalInfo" 
      :stogeryToken="stogerytoken"
      :primaryEmail="email" 
      :mainPhone="mainPhone" 
      :addNewPhone="addnewphone" 
      :editPhone="editphone" 
      :deletePhone="deletephone" 
      :changeMainPhone="changemainphone" 
      :returnUrl="returnurl"></contact-details-component>

    <!-- <account-erasure-component v-if="this.title !== 'Name' && this.title !== 'Email' && this.title !== 'Phone'" 
      @changeTitle="changeTitle" 
      :returnPersonalInfo="returnPersonalInfo"></account-erasure-component> -->
  </div>
</template>

<script>
  import { ref } from 'vue';
  import basicInfoComponent from './components/basic-info-component.vue'
  import ContactDetailsComponent from './components//contact-details.component.vue'
  import AccountErasureComponent from './components/account-erasure-component.vue'
  import PageTitle from '../page-title.vue';
  import PersonalName from './components/personal-name.vue';


export default {
  components: { basicInfoComponent, ContactDetailsComponent, AccountErasureComponent, PageTitle, PersonalName, },
    name: 'PersonalInfo',
    props: {
      abbr: ref(String),
      name: ref(String),
      first: ref(String),
      last: ref(String),
      email: ref(String),
      mainPhone: ref(Object),
      savenames: ref(String),
      stogeryname: ref(String),
      stogerytoken: ref(String),
      addnewphone: ref(String),
      editphone: ref(String),
      deletephone: ref(String),
      changemainphone: ref(String),
      returnurl: ref(String)
    },
    data() {
      return {
        title: 'Personal Info',
        showBtn: false,
        text: 'Account info and preferences across all our services',
        returnPersonalInfo: false
      }
    },
    methods: {
      changeTitle(newTitle) {
        this.title = newTitle;
        switch(newTitle) {
          case "Name":
            this.showBtn = true;
            this.returnPersonalInfo = false;
            break;
          case "Personal Info":
            this.showBtn = false;
            this.returnPersonalInfo = true;
            break;
          case "Email":
            this.showBtn = true;
            this.returnPersonalInfo = false;
            break;
          case "Phone":
            this.showBtn = true;
            this.returnPersonalInfo = false;
            break;
            case "Erasure request":
            this.showBtn = true;
            this.returnPersonalInfo = false;
            break;
          default:
            this.showBtn = false;
        }
      },
      // on Back button return to Security view
      setComponent(component) {
        this.component = component;
        this.changeTitle(component);
        this.returnPersonalInfo = true;
      }
    }
}
</script>
