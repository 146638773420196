<template>
  <div>
    <page-title
        :title="title"
        :text="text"
        parentSection="Dashboard"></page-title>
    <mdb-container class="products-container">
      <mdb-row>
        <mdb-col md="12">
          <h3 class="section-title">Manage your account</h3>
          <mdb-row>
            <!-- To do - return data from controller, different class for any product, change icons due to variable from BE -->
            <mdb-col md="6" class="mb-4">
              <shortcut-component
                  :linkProp="'/Account/PersonalInfo/' + '?returnUrl=' + returnurl"
                  :titleProp="'Personal Info'"
                  :iconProp="'icon-personal'"></shortcut-component>
            </mdb-col>
            <mdb-col md="6" class="mb-4">
              <shortcut-component
                  :linkProp="'/Account/Security/' + '?returnUrl=' + returnurl"
                  :titleProp="'Account Security'"
                  :iconProp="'icon-security'"></shortcut-component>
            </mdb-col>
            <mdb-col md="6" class="mb-4">
              <shortcut-component
                  :linkProp="'/Account/Security?section=mfa' + '&returnUrl=' + returnurl"
                  :titleProp="'2FA Settings'"
                  :iconProp="'icon-badge'"></shortcut-component>
            </mdb-col>
            <mdb-col md="6" class="mb-4">
              <shortcut-component
                  :linkProp="'/Account/Security?section=password' + '&returnUrl=' + returnurl"
                  :titleProp="'Manage Password'"
                  :iconProp="'icon-key'"></shortcut-component>
            </mdb-col>
          </mdb-row>

          <mdb-row>
            <mdb-col md="12">
              <h3 class="section-title mt-4">All our products</h3>
              <slider-component
                  :iconProp="'icon-security'"></slider-component>
            </mdb-col>
          </mdb-row>
        </mdb-col>

<!--        <mdb-col md="4">-->
<!--          <h3 class="section-title products-title">Your Products</h3>-->

<!--          <product-component v-for="product in yourProducts"-->
<!--              :productLinkProp=product.Url-->
<!--              :productNameProp=product.Title />-->
<!--        </mdb-col>-->
      </mdb-row>
    </mdb-container>
  </div>
</template>

<script>
import {ref} from 'vue';
import {mdbCarousel} from 'mdbvue';
import PageTitle from '../page-title.vue'
import ProductComponent from './components/product-component.vue'
import ShortcutComponent from './components/shortcut-component'
import SliderComponent from './components/all-products-slider.vue'

import carousel from 'vue-owl-carousel'
import th from "vue2-datepicker/locale/es/th";

export default {
  components: {ProductComponent, SliderComponent, ShortcutComponent, PageTitle, carousel},
  name: 'Dashboard',
  props: {
    title: ref(String),
    text: ref(String),
    returnurl: ref(String),
    yourProductSources: ref(String)
  },
  data() {
    return {
      yourProducts: []
    }
  },
  mounted() {
    if(this.yourProductSources && this.yourProductSources.length > 0) {
      this.yourProducts = JSON.parse(this.yourProductSources);
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  @include respond-to(max-width, 575px) {
    padding: 20px 15px;
    text-align: center;
  }
}

.products-title {
  @include respond-to(max-width, 991px) {
    margin-top: 40px;
  }
}
</style>