<template>
    <div>
        <div class="white-box" v-if="!this.hasGeneratedCodes">
            <h3 class="mb-4">Your Backup Codes</h3>
            <mdb-btn type="button" class="btn--outline-primary w-100 d-flex align-items-center justify-content-center" rounded @click="generateRequest">Generate Codes <i class="icon icon-add ml-1"></i></mdb-btn>
        </div>
        <div v-if="this.hasGeneratedCodes">
            <div class="white-box">
                <div class="d-flex justify-content-between align-items-center mb-4 pb-3">
                    <div>
                        <div class="d-flex">
                            <h3 class="mb-3">Your Backup Codes</h3>
                            <span class="ml-2">(Created {{interval}} ago)</span>
                        </div>
                        <p class="mb-0">{{this.codes.filter(code => code.isUsed == false).length}} backup codes remaining</p>
                    </div>
                    <div class="d-flex">
                        <mdb-btn type="button" class="btn--icon p-2" rounded @click.native="modalResetCodes = true"><i class="icon icon-generate fs_35"></i></mdb-btn>
                        <mdb-btn type="button" class="btn--icon p-2" rounded  @click.native="modalDeleteCodes = true"><i class="icon icon-bin fs_35"></i></mdb-btn>
                    </div>
                </div>
                <div class="row backup-codes">
                    <div class="col-6 d-flex justify-content-center" v-bind:key="index" v-for="(code, index) in this.codes">
                        <p class="code" v-bind:class="{'used': code.isUsed }">{{code.code}}</p>
                    </div>
                </div>
                <div class="row backup-codes d-none" id="backupCodes">
                    <p>SAVE YOUR BACKUP CODES</p>
                    <p>Keep these backup codes somewhere safe but accessible.</p>
                    <div class="col-6 d-flex justify-content-center" v-bind:key="index" v-for="(code, index) in this.codes">
                        <p class="code" v-bind:class="{'used': code.isUsed }" v-if="!code.isUsed">{{index}}.   {{code.code}}</p>
                    </div>
                    <p>* You can only use each backup code once.</p>
                    <p>* These codes were generated on: {{this.timeOfCration}}</p>
                </div>
            </div>
            <div class="btns-group d-flex justify-content-center">
                <mdb-btn type="button" class="btn--outline-primary w-25" rounded @click="printPageArea"> Print </mdb-btn>
                <mdb-btn type="button" class="btn--outline-primary w-25" rounded @click="downloadBackupCodes"><i class="icon icon-download mr-2"></i> Download </mdb-btn>
            </div>
        </div>
        <mdb-modal :show="modalDeleteCodes" @close="modalDeleteCodes = false" centered>
            <mdb-modal-body class="text-center py-4 mt-2 px-5">
                <h3 class="font-weight-bold mb-4">Are you sure you want to delete your backup codes?</h3>
                <p class="w-75 text-center m-auto">Remember to re-generate your backup codes or you might risk losing access to your account</p>
            </mdb-modal-body>
            <mdb-modal-footer class="d-flex justify-content-center pt-0 border-0">
                <mdb-btn class="btn--back-primary" @click.native="modalDeleteCodes = false">Cancel</mdb-btn>
                <mdb-btn class="btn--delete" @click="deleteRequest">Delete</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>

        <mdb-modal :show="modalResetCodes" @close="modalResetCodes = false" centered>
            <mdb-modal-body class="text-center py-4 mt-2 px-5">
                <h3 class="font-weight-bold mb-4">Are you sure you want to reset your backup codes?</h3>
                <p class="w-75 text-center m-auto">Remember to save your new backup codes or you might risk losing access to your account</p>
            </mdb-modal-body>
            <mdb-modal-footer class="d-flex justify-content-center pt-0 border-0">
                <mdb-btn class="btn--back-primary" @click.native="modalResetCodes = false">Cancel</mdb-btn>
                <mdb-btn class="btn--delete" @click="generateRequest">Reset</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        
    </div>
</template>
<script>
    import axios from 'axios';
  import {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn
  } from 'mdbvue';
import { get, post } from '../../../services/http-common.js';
import { ref } from 'vue';
export default {
    components: {
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn
    },
    name: 'backupCodes',
    data() {
        return {
            hasGeneratedCodes: Boolean,
            codes: [],
            lastUpdatedAt: String,
            timeOfCration: String,
            interval: String,
            modalDeleteCodes: false,
            modalResetCodes: false
        }
    },
    props: {
        returnUrl: ref(String),
        stogeryName: ref(String),
        stogeryToken: ref(String),
        getBackUpCodes: ref(String),
        generateBackUpCodes: ref(String),
        removeBackUpCodes: ref(String)
    },
    mounted() {
        this.getCodes();
    },
    methods: {
        timeOfCreation() {
            this.timeOfCration = new Date(this.lastUpdatedAt).toDateString().split(' ').slice(1).join(' ');
        },
        printPageArea() {
            var printContent = document.getElementById('backupCodes');
            var WinPrint = window.open('', '', 'width=900,height=650');
            WinPrint.document.write(printContent.innerHTML);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        },
        getCodes() {
            get(this.getBackUpCodes)
            .then((response) => {
                this.hasGeneratedCodes = response.data.hasGeneratedCodes;
                this.codes = response.data.codes;
                // Calculating last updated time codes
                this.lastUpdatedAt = response.data.lastUpdatedAt;
                this.timeOfCreation();
                let today = new Date();
                let lastUpdated = new Date(this.lastUpdatedAt);
                this.timeSince(today, lastUpdated);
            })
            .catch((error) => {
            if (error.response) {
              // Request made and server responded
              console.log(error.response);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
        },
        downloadBackupCodes() {
            let data = new FormData();
            data.append('ReturnUrl', this.returnUrl);
            var url = '/Account/Security/TwoStepVerification/BackupCodes/Download?returnUrl=' + this.returnUrl + '';

            axios({
                url: url, // File URL Goes Here
                method: 'GET',
                responseType: 'blob',
            }).then((res) => {
                var FILE = new Blob([res.data], { type: 'text' });
                var objectUrl = window.URL.createObjectURL(FILE);
                var docUrl = document.createElement('a');
                docUrl.href = objectUrl;
                docUrl.setAttribute('download', 'backupCodes.txt');
                document.body.appendChild(docUrl);
                docUrl.click();                
            }).catch((error) => {
                if (error.response) {  
                // Request made and server responded
                console.log(error.response);
                } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                }
            })
        },
        generateRequest() {
            // To close the modal
            this.modalResetCodes = false;

            let data = new FormData();
            data.append("ReturnUrl", this.returnUrl);

            post(this.generateBackUpCodes, data, this.stogeryToken)
            .then((response) => {
                console.log(response);
                this.hasGeneratedCodes = response.data.hasGeneratedCodes;
                this.codes = response.data.codes;
                // Calculating last updated time codes
                this.lastUpdatedAt = response.data.lastUpdatedAt;
                this.timeOfCreation();
                let today = new Date();
                let lastUpdated = new Date(this.lastUpdatedAt);
                this.timeSince(today, lastUpdated);
            })
            .catch((error) => {
                if (error.response) {
                    // Request made and server responded
                    this.validation.invalid = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            })
        },
        deleteRequest() {
            // To close modal
            this.modalDeleteCodes = false;

            let data = new FormData();
            data.append("ReturnUrl", this.returnUrl);

            post(this.removeBackUpCodes, data, this.stogeryToken)
            .then((response) => {
                this.hasGeneratedCodes = false;
                this.codes = [];
            })
            .catch((error) => {
                if (error.response) {
                    // Request made and server responded
                    this.validation.invalid = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            })
        },
        timeSince(today, lastUpdated) {
            let seconds = Math.floor((today - lastUpdated) / 1000);
            this.interval = Math.floor(seconds / 31536000);

            if (this.interval > 1) {
                return this.interval = this.interval + " years";
            }
            this.interval = Math.floor(seconds / 2592000);
            if (this.interval > 1) {
                return this.interval = this.interval + " months";
            }
            this.interval = Math.floor(seconds / 86400);
            if (this.interval > 1) {
                return this.interval = this.interval + " days";
            }
            this.interval = Math.floor(seconds / 3600);
            if (this.interval > 1) {
                return this.interval = this.interval + " hours";
            }
            this.interval = Math.floor(seconds / 60);
            if (this.interval >= 1) {
                return this.interval = this.interval + " minutes";
            }
            return this.interval =  Math.floor(seconds) + " seconds";
        }
    }
}
</script>
