var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.mfaStatus)?_c('div',[(_vm.step1Disabled)?_c('div',[_vm._m(0),_c('div',{staticClass:"btns-group d-flex justify-content-center justify-content-sm-end"},[_c('mdb-btn',{staticClass:"btn--back ml-sm-auto",attrs:{"type":"button","rounded":""},on:{"click":_vm.onCancelClicked}},[_vm._v("Cancel")]),_c('mdb-btn',{staticClass:"btn--primary",attrs:{"type":"button","rounded":""},on:{"click":function($event){_vm.step1Disabled = false;
						_vm.step2Disabled = true;}}},[_vm._v("Continue")])],1)]):_vm._e(),(_vm.step2Disabled)?_c('div',[_c('div',{staticClass:"white-box mfa-container"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-content-between align-items-center"},[_c('p',{staticClass:"mfa-info__list pb-2 font-weight-bold"},[_vm._v(" 2. Scan the QR code using Google Authenticator ")]),_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.qrSrc,"alt":_vm.qrAlt,"width":"263","height":"230"}})])]),_c('div',{staticClass:"white-box"},[_c('div',{staticClass:"mfa-info"},[_c('p',{staticClass:"mfa-info__list pb-2 font-weight-bold"},[_vm._v(" 3. Enter and verify the authentication code generated by the app ")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.verifyCode),expression:"verifyCode"}],staticClass:"form-control code-input",class:{
								'is-invalid': _vm.validation.invalid.ProcessSecurityCode,
							},attrs:{"type":"text","id":"AuthCode","placeholder":"XXX-XXX"},domProps:{"value":(_vm.verifyCode)},on:{"focus":function($event){return _vm.clearValidation('ProcessSecurityCode')},"input":function($event){if($event.target.composing)return;_vm.verifyCode=$event.target.value}}}),_vm._l((_vm.validation.invalid.ProcessSecurityCode),function(error,index){return _c('div',{key:index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2)])]),_c('div',{staticClass:"btns-group d-flex justify-content-center align-items-center justify-content-sm-end"},[_c('mdb-btn',{staticClass:"btn--back ml-sm-auto",attrs:{"type":"button","rounded":""},on:{"click":function($event){_vm.step1Disabled = true;
						_vm.step2Disabled = false;}}},[_vm._v("Go Back")]),_c('vue-button-spinner',{attrs:{"is-loading":_vm.isLoading,"disabled":_vm.isLoading}},[_c('mdb-btn',{staticClass:"btn--primary",attrs:{"type":"button","rounded":"","disabled":_vm.isLoading},on:{"click":_vm.sendVerifyToServer}},[_vm._v("Verify")])],1)],1)]):_vm._e()]):_vm._e(),(_vm.mfaStatus)?_c('div',[(_vm.step1Enabled)?_c('div',[_c('div',{staticClass:"blue-box"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-3"},[_c('h3',{staticClass:"mb-0 text-white"},[_vm._v("Two-Factor Login is Enabled")]),_c('mdb-btn',{staticClass:"btn--outline-secondary font-weight-normal py-2",attrs:{"type":"button","rounded":""},on:{"click":function($event){_vm.step2Enabled = true;
							_vm.step1Enabled = false;}}},[_vm._v("Disable")])],1),_c('p',{staticClass:"text-white"},[_vm._v(" Keep unauthorized users out of your account by using both your password and your phone. Setup your two-factor authentication codes in 3 steps. You will only be asked to enter validation code when you try to login. ")])])]):_vm._e(),(_vm.step2Enabled)?_c('div',[_vm._m(1),(_vm.googleAuthBox)?_c('div',[_c('div',{staticClass:"white-box googleAuthBox"},[_c('h3',{staticClass:"mb-5"},[_vm._v(" Enter the authentication code from the Google Authenticator App ")]),_c('div',{staticClass:"form-group mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.disableMfaGoogleAuthCode),expression:"disableMfaGoogleAuthCode"}],staticClass:"form-control code-input",class:{
								'is-invalid': _vm.validation.invalid.ProcessSecurityCode,
							},attrs:{"type":"text","id":"AuthCode","placeholder":"XXX-XXX"},domProps:{"value":(_vm.disableMfaGoogleAuthCode)},on:{"focus":function($event){return _vm.clearValidation('ProcessSecurityCode')},"input":function($event){if($event.target.composing)return;_vm.disableMfaGoogleAuthCode=$event.target.value}}}),_vm._l((_vm.validation.invalid.ProcessSecurityCode),function(error,index){return _c('div',{key:index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2),_c('p',{staticClass:"m-0 text-center"},[_vm._v(" Don’t have access you your Google Authenticator App? ")]),_c('p',{staticClass:"m-0 text-center"},[_vm._v(" Enter a "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.backupCodeBox = true;
								_vm.googleAuthBox = false;}}},[_vm._v("Backup Code instead")])])]),_c('div',{staticClass:"btns-group d-flex justify-content-center align-items-center justify-content-sm-end"},[_c('mdb-btn',{staticClass:"btn--back ml-sm-auto",attrs:{"type":"button","rounded":""},on:{"click":_vm.sendTitle}},[_vm._v("Cancel")]),_c('vue-button-spinner',{attrs:{"is-loading":_vm.isLoading,"disabled":_vm.isLoading}},[_c('mdb-btn',{staticClass:"mfa-disable",attrs:{"type":"button","rounded":"","disabled":_vm.isLoading},on:{"click":_vm.sendGoogleAuthToDisableMFA}},[_vm._v("Disable MFA")])],1)],1)]):_vm._e(),(_vm.backupCodeBox)?_c('div',[_c('div',{staticClass:"white-box backupCodeBox"},[_c('h3',{staticClass:"mb-5"},[_vm._v("Enter your Backup Code")]),_c('div',{staticClass:"form-group mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Code),expression:"Code"}],staticClass:"form-control code-input",class:{ 'is-invalid': _vm.validation.invalid.Code },attrs:{"type":"text","id":"AuthCode","placeholder":"123-565-169"},domProps:{"value":(_vm.Code)},on:{"focus":function($event){return _vm.clearValidation('Code')},"input":function($event){if($event.target.composing)return;_vm.Code=$event.target.value}}}),_vm._l((_vm.validation.invalid.Code),function(error,index){return _c('div',{key:index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2),_c('p',{staticClass:"m-0 text-center"},[_vm._v(" Don’t have access you your Google Authenticator App? ")]),_c('p',{staticClass:"m-0 text-center"},[_vm._v(" Enter a "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.googleAuthBox = true;
								_vm.backupCodeBox = false;}}},[_vm._v("GoogleAuthenticator Code")]),_vm._v(" instead ")])]),_c('div',{staticClass:"btns-group d-flex justify-content-center justify-content-sm-end"},[_c('mdb-btn',{staticClass:"btn--back ml-sm-auto",attrs:{"type":"button","rounded":""},on:{"click":_vm.sendTitle}},[_vm._v("Cancel")]),_c('mdb-btn',{staticClass:"btn--primary",attrs:{"type":"button","rounded":""},on:{"click":_vm.sendBackUpCodeToDisableMFA}},[_vm._v("Disable MFA")])],1)]):_vm._e()]):_vm._e()]):_vm._e(),_c('mdb-modal',{staticClass:"pe-none",attrs:{"show":_vm.modalMfaEnabled,"centered":""},on:{"close":function($event){_vm.modalMfaEnabled = false}}},[_c('mdb-modal-body',{staticClass:"text-center py-4 mt-2 px-5"},[_c('h3',{staticClass:"font-weight-bold mb-0"},[_vm._v("Your MFA has been enabled")])]),_c('mdb-modal-footer',{staticClass:"d-flex justify-content-center pt-0 border-0"},[_c('mdb-btn',{staticClass:"btn--primary",nativeOn:{"click":function($event){return _vm.succesfullyEnabledMFA.apply(null, arguments)}}},[_vm._v("Continue")])],1)],1),_c('return-back-modal',{ref:"modalComponent",on:{"goBack":_vm.sendTitle}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white-box mfa-container"},[_c('p',{staticClass:"mfa-info__list pb-2 font-weight-bold"},[_vm._v(" 1. Download the Google Authenticator App ")]),_c('p',{staticClass:"mb-5"},[_vm._v(" Keep unauthorized users out of your account by using both your password and your phone. Setup your two-factor authentication codes in 3 steps. You will only be asked to enter validation code when you try to login. ")]),_c('div',{staticClass:"mfa-info__link mb-5 text-center"},[_c('a',{attrs:{"href":"https://apps.apple.com/us/app/google-authenticator/id388497605","target":"_blank"}},[_c('img',{staticClass:"pr-2 mr-3",attrs:{"src":require("@/assets/img/appStore.png"),"width":"135","height":"40","alt":"AppStore"}})]),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/googlePlay.png"),"width":"135","height":"40","alt":"GooglePlay"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"red-box"},[_c('p',{staticClass:"font-weight-bold text-white mb-2 d-flex align-items-center fs_18"},[_c('i',{staticClass:"icon icon-issue mr-1"}),_vm._v(" Please Note: ")]),_c('p',{staticClass:"text-white mb-0"},[_vm._v(" Two-Factor Login is mandatory for many of our services in order to keep your personal information secure ")])])
}]

export { render, staticRenderFns }