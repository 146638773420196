<template>
  <div>
    <mdb-modal :show="modal" @close="modal = false" centered >
      <mdb-modal-body class="text-center py-4 mt-2 px-5">
        <h3 class="font-weight-bold mb-0">Are you sure you want to change your Primary phone Number?</h3>
      </mdb-modal-body>
      <mdb-modal-footer class="d-flex justify-content-center pt-0 border-0">
        <mdb-btn class="btn--back" @click.native="modal = false">Cancel</mdb-btn>
        <mdb-btn class="btn--primary" @click.native="modal = false" @click="updatePhonePrimary">Change</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn
  } from 'mdbvue';
  export default {
    components: {
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbBtn
    },
    name: 'updatePhonePrimaryModal',
    data() {
      return {
        modal: false
      };
    },
    methods: {
      showModal (data) {
        if(data) {
            this.modal = true;
        }
      },
      updatePhonePrimary() {
          this.$emit("updatePhonePrimary");
      }
    }
  };
</script>