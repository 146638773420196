<template>
	<div>
		<!--disabled  -->
		<div v-if="!mfaStatus">
			<div v-if="step1Disabled">
				<div class="white-box mfa-container">
					<p class="mfa-info__list pb-2 font-weight-bold">
						1. Download the Google Authenticator App
					</p>
					<p class="mb-5">
						Keep unauthorized users out of your account by using both your
						password and your phone. Setup your two-factor authentication codes
						in 3 steps. You will only be asked to enter validation code when you
						try to login.
					</p>
					<div class="mfa-info__link mb-5 text-center">
						<a
							href="https://apps.apple.com/us/app/google-authenticator/id388497605"
							target="_blank"
						>
							<img
								class="pr-2 mr-3"
								src="@/assets/img/appStore.png"
								width="135"
								height="40"
								alt="AppStore"
							/>
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
							target="_blank"
						>
							<img
								src="@/assets/img/googlePlay.png"
								width="135"
								height="40"
								alt="GooglePlay"
							/>
						</a>
					</div>
				</div>
				<div
					class="btns-group d-flex justify-content-center justify-content-sm-end"
				>
					<mdb-btn
						type="button"
						class="btn--back ml-sm-auto"
						rounded
						@click="onCancelClicked"
						>Cancel</mdb-btn
					>
					<mdb-btn
						type="button"
						class="btn--primary"
						rounded
						@click="
							step1Disabled = false;
							step2Disabled = true;
						"
						>Continue</mdb-btn
					>
				</div>
			</div>
			<div v-if="step2Disabled">
				<div class="white-box mfa-container">
					<div
						class="d-flex flex-column flex-sm-row justify-content-between align-items-center"
					>
						<p class="mfa-info__list pb-2 font-weight-bold">
							2. Scan the QR code using Google Authenticator
						</p>
						<img
							:src="qrSrc"
							:alt="qrAlt"
							width="263"
							height="230"
							class="img-fluid"
						/>
					</div>
				</div>
				<div class="white-box">
					<div class="mfa-info">
						<p class="mfa-info__list pb-2 font-weight-bold">
							3. Enter and verify the authentication code generated by the app
						</p>
						<div class="form-group">
							<input
								type="text"
								id="AuthCode"
								class="form-control code-input"
								v-model="verifyCode"
								placeholder="XXX-XXX"
								v-bind:class="{
									'is-invalid': validation.invalid.ProcessSecurityCode,
								}"
								v-on:focus="clearValidation('ProcessSecurityCode')"
							/>
							<div
								class="invalid-feedback"
								v-bind:key="index"
								v-for="(error, index) in validation.invalid.ProcessSecurityCode"
							>
								{{ error }}
							</div>
						</div>
					</div>
				</div>
				<div
					class="btns-group d-flex justify-content-center align-items-center justify-content-sm-end"
				>
					<mdb-btn
						type="button"
						class="btn--back ml-sm-auto"
						rounded
						@click="
							step1Disabled = true;
							step2Disabled = false;
						"
						>Go Back</mdb-btn
					>

					<vue-button-spinner :is-loading="isLoading" :disabled="isLoading">
						<mdb-btn
							type="button"
							class="btn--primary"
							rounded
							:disabled="isLoading"
							@click="sendVerifyToServer"
							>Verify</mdb-btn
						>
					</vue-button-spinner>
				</div>
			</div>
		</div>
		<!-- enabled -->
		<div v-if="mfaStatus">
			<div v-if="step1Enabled">
				<div class="blue-box">
					<div class="d-flex justify-content-between align-items-center mb-3">
						<h3 class="mb-0 text-white">Two-Factor Login is Enabled</h3>
						<mdb-btn
							type="button"
							class="btn--outline-secondary font-weight-normal py-2"
							rounded
							@click="
								step2Enabled = true;
								step1Enabled = false;
							"
							>Disable</mdb-btn
						>
					</div>
					<p class="text-white">
						Keep unauthorized users out of your account by using both your
						password and your phone. Setup your two-factor authentication codes
						in 3 steps. You will only be asked to enter validation code when you
						try to login.
					</p>
				</div>
			</div>
			<div v-if="step2Enabled">
				<div class="red-box">
					<p
						class="font-weight-bold text-white mb-2 d-flex align-items-center fs_18"
					>
						<i class="icon icon-issue mr-1"></i> Please Note:
					</p>
					<p class="text-white mb-0">
						Two-Factor Login is mandatory for many of our services in order to
						keep your personal information secure
					</p>
				</div>
				<div v-if="googleAuthBox">
					<div class="white-box googleAuthBox">
						<h3 class="mb-5">
							Enter the authentication code from the Google Authenticator App
						</h3>
						<div class="form-group mb-2">
							<input
								type="text"
								id="AuthCode"
								class="form-control code-input"
								v-model="disableMfaGoogleAuthCode"
								placeholder="XXX-XXX"
								v-bind:class="{
									'is-invalid': validation.invalid.ProcessSecurityCode,
								}"
								v-on:focus="clearValidation('ProcessSecurityCode')"
							/>
							<div
								class="invalid-feedback"
								v-bind:key="index"
								v-for="(error, index) in validation.invalid.ProcessSecurityCode"
							>
								{{ error }}
							</div>
						</div>
						<p class="m-0 text-center">
							Don’t have access you your Google Authenticator App?
						</p>
						<p class="m-0 text-center">
							Enter a
							<a
								href="#"
								@click="
									backupCodeBox = true;
									googleAuthBox = false;
								"
								>Backup Code instead</a
							>
						</p>
					</div>
					<div
						class="btns-group d-flex justify-content-center align-items-center justify-content-sm-end"
					>
						<mdb-btn
							type="button"
							class="btn--back ml-sm-auto"
							rounded
							@click="sendTitle"
							>Cancel</mdb-btn
						>

						<vue-button-spinner :is-loading="isLoading" :disabled="isLoading">
							<mdb-btn
								type="button"
								class="mfa-disable"
								rounded
								:disabled="isLoading"
								@click="sendGoogleAuthToDisableMFA"
								>Disable MFA</mdb-btn
							>
						</vue-button-spinner>
					</div>
				</div>
				<div v-if="backupCodeBox">
					<div class="white-box backupCodeBox">
						<h3 class="mb-5">Enter your Backup Code</h3>
						<div class="form-group mb-2">
							<input
								type="text"
								id="AuthCode"
								class="form-control code-input"
								v-model="Code"
								placeholder="123-565-169"
								v-bind:class="{ 'is-invalid': validation.invalid.Code }"
								v-on:focus="clearValidation('Code')"
							/>
							<div
								class="invalid-feedback"
								v-bind:key="index"
								v-for="(error, index) in validation.invalid.Code"
							>
								{{ error }}
							</div>
						</div>
						<p class="m-0 text-center">
							Don’t have access you your Google Authenticator App?
						</p>
						<!-- <p class="m-0 text-center">Enter a <a href="#" @click="googleAuthBoxShow">GoogleAuthenticator Code</a> instead</p> -->
						<p class="m-0 text-center">
							Enter a
							<a
								href="#"
								@click="
									googleAuthBox = true;
									backupCodeBox = false;
								"
								>GoogleAuthenticator Code</a
							>
							instead
						</p>
					</div>
					<div
						class="btns-group d-flex justify-content-center justify-content-sm-end"
					>
						<mdb-btn
							type="button"
							class="btn--back ml-sm-auto"
							rounded
							@click="sendTitle"
							>Cancel</mdb-btn
						>
						<mdb-btn
							type="button"
							class="btn--primary"
							rounded
							@click="sendBackUpCodeToDisableMFA"
							>Disable MFA</mdb-btn
						>
					</div>
				</div>
			</div>
		</div>
		<mdb-modal
			:show="modalMfaEnabled"
			@close="modalMfaEnabled = false"
			centered
			class="pe-none"
		>
			<mdb-modal-body class="text-center py-4 mt-2 px-5">
				<h3 class="font-weight-bold mb-0">Your MFA has been enabled</h3>
			</mdb-modal-body>
			<mdb-modal-footer class="d-flex justify-content-center pt-0 border-0">
				<mdb-btn class="btn--primary" @click.native="succesfullyEnabledMFA"
					>Continue</mdb-btn
				>
			</mdb-modal-footer>
		</mdb-modal>

		<return-back-modal
			ref="modalComponent"
			@goBack="sendTitle"
		></return-back-modal>
	</div>
</template>

<script>
import {
	mdbModal,
	mdbModalHeader,
	mdbModalTitle,
	mdbModalBody,
	mdbModalFooter,
	mdbBtn,
} from 'mdbvue';
import returnBackModal from '../../return-back-modal.vue';
import { ref } from 'vue';
import { get, post } from '../../../services/http-common.js';
import VueButtonSpinner from 'vue-button-spinner';

export default {
	components: {
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		mdbModalFooter,
		mdbBtn,
		returnBackModal,
		VueButtonSpinner,
	},
	name: 'mfa',
	data() {
		return {
			mfaEnabled: false,
			isLoading: false,
			step1Disabled: true,
			step2Disabled: false,
			step1Enabled: true,
			step2Enabled: false,
			googleAuthBox: true,
			backupCodeBox: false,
			verifyCode: '',
			validation: {
				invalid: {},
				valid: {},
			},
			mfaStatus: this.mfaStatusProp,
			disableMfaGoogleAuthCode: '',
			Code: '',
			modalMfaEnabled: false,
		};
	},
	props: {
		returnUrl: ref(String),
		enableMfa: ref(String),
		stogeryName: ref(String),
		stogeryToken: ref(String),
		qrSrc: ref(String),
		qrAlt: ref(String),
		mfaStatusProp: ref(String),
		disableMFAGoogleAuth: ref(String),
		disableMFABackupCode: ref(String),
	},
	methods: {
		// opening returnBackModal
		onCancelClicked() {
			this.$refs.modalComponent.showModal(true);
		},
		sendTitle() {
			this.$emit('showComponent', 'Security');
		},
		clearValidation: function (field) {
			this.validation.invalid[field] = '';
			this.validation.valid[field] = '';
			this.$forceUpdate();
		},
		sendVerifyToServer() {
			let data = new FormData();
			data.append('ProcessSecurityCode', this.verifyCode);
			data.append('ReturnUrl', this.returnUrl);
			this.isLoading = true;

			post(this.enableMfa, data, this.stogeryToken)
				.then((response) => {
					this.modalMfaEnabled = true;

					const mfaBtn = document.getElementById('mfa-btn');
					mfaBtn.innerText = 'MFA: ON';
					mfaBtn.classList.add('mfa-on');

					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;

					if (error.response) {
						// Request made and server responded
						this.validation.invalid = error.response.data;
					} else if (error.request) {
						// The request was made but no response was received
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
				});
		},
		succesfullyEnabledMFA() {
			this.modalMfaEnabled = false;
			this.mfaStatus = true;
			this.$emit('updateMFAStatusEnabled', this.mfaStatus);
			this.$emit('showComponent', 'Backup Codes');
		},
		sendGoogleAuthToDisableMFA() {
			let data = new FormData();
			data.append('ProcessSecurityCode', this.disableMfaGoogleAuthCode);
			data.append('ReturnUrl', this.returnUrl);
			this.isLoading = true;

			post(this.disableMFAGoogleAuth, data, this.stogeryToken)
				.then((response) => {
					this.mfaStatus = false;
					this.$emit('updateMFAStatusDisabled', this.mfaStatus);
					this.sendTitle();

					const mfaBtn = document.getElementById('mfa-btn');
					mfaBtn.innerText = 'MFA: OFF';
					mfaBtn.classList.remove('mfa-on');

					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
					if (error.response) {
						// Request made and server responded
						this.validation.invalid = error.response.data;
					} else if (error.request) {
						// The request was made but no response was received
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
				});
		},
		sendBackUpCodeToDisableMFA() {
			let data = new FormData();
			data.append('Code', this.Code);
			data.append('ReturnUrl', this.returnUrl);

			post(this.disableMFABackupCode, data, this.stogeryToken)
				.then((response) => {
					this.mfaStatus = false;
					this.$emit('updateMFAStatusDisabled', this.mfaStatus);
					this.sendTitle();
				})
				.catch((error) => {
					if (error.response) {
						// Request made and server responded
						this.validation.invalid = error.response.data;
					} else if (error.request) {
						// The request was made but no response was received
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
				});
		},
	},
};
</script>