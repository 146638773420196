<template>
  <a class="white-box__item d-flex flex-wrap justify-content-between align-items-center" :class="[notClickable ? 'pe-none' : '']" @click="sendTitle">
    <p class="title mb-sm-0">{{ title }}</p>
    
    <p v-if="!isPhone" class="info mb-0">{{ text }}</p>
    <div v-else>
      <p v-for="phone in text" class="info mb-0">{{ phone.printPhone }} <span v-if="phone.isMain">(Primary)</span></p>
    </div>
    <span class="avatar m-0" v-if="title === 'Avatar'">{{ abbr }}</span>
    <i class="icon icon-arrow" v-else></i>
  </a>
</template>

<script>
import { ref } from 'vue';
  export default {
    name: 'InfoRow',
    props: {
      title: ref(String),
      text: ref(String) | ref(Array),
      notClickable: ref(Boolean),
      abbr: ref(String),
      isPhone: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      sendTitle() {
        this.$emit("showComponent", this.title);
      }
    }
  }
</script>