<template>
  <section class="wrapper wrapper-enroll col-lg-6 d-flex flex-column align-items-center justify-content-center">
    <div class="col-xl-10 offset-xl-1 d-flex flex-column align-items-center justify-content-center">
      <div v-if="this.section === 1">
        <h2 class="form-title font-weight-bold">Enter New Password</h2>
        <div class="w-100">
          <div class="form-group input-group">
            <label for="newPassword" class="d-block w-100">New Password*</label>
            <input :type="isPasswordVisible.password ? 'text' : 'password'"
                   id="newPassword" 
                   class="form-control" 
                   v-bind:class="{'is-invalid': validation.invalid.NewPassword }" 
                   v-model="newPassword" 
                   v-on:focus="clearValidation('NewPassword')" 
                   @focus="passRequirementsVisible = true"
                   @input="passwordCheck" placeholder="Enter your Password">

            <!-- Password field's toggle button -->
            <div class="input-group-append">
              <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                <span slot="tip">{{ isPasswordVisible.password ? 'Hide password' : 'Show password' }}</span>
                <button slot="reference" 
                        class="btn btn--outline-primary m-0" 
                        type="button" 
                        @click="togglePasswordVisibility('password')">
                  <i :class="['icon', isPasswordVisible.password ? 'icon-eye-slash' : 'icon-eye']"></i>
                </button>
              </mdb-tooltip>
            </div>

            <div class="invalid-feedback" v-for="error in validation.invalid.NewPassword">{{ error }}</div>
            <div class="w-100">
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : newPassword.length > 7}"><i class="frmIcon" :class="newPassword.length > 7 ? 'icon-checkmark' : 'icon-close'"></i>at least 8 characters</p>
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_uppercase }"><i class="frmIcon" :class="has_uppercase ? 'icon-checkmark' : 'icon-close'"></i>upper case characters (A-Z)</p>
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_lowercase }"><i class="frmIcon" :class="has_lowercase ? 'icon-checkmark' : 'icon-close'"></i>lower case characters (a-z)</p>
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_number }"><i class="frmIcon" :class="has_number ? 'icon-checkmark' : 'icon-close'"></i>numbers (0-9)</p>
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_special }"><i class="frmIcon" :class="has_special ? 'icon-checkmark' : 'icon-close'"></i>special symbols (! , ? @ $ & * etc.)</p>
            </div>
          </div>

          <div class="form-group input-group">
            <label for="confirmPassword" class="d-block w-100">Confirm Password*</label>
            <input id="confirmPassword" 
                  :type="isPasswordVisible.confirmPassword ? 'text' : 'password'" 
                   v-bind:class="{'is-invalid': validation.invalid.ConfirmPassword }" 
                   class="form-control" 
                   v-on:input="validation.invalid.ConfirmPassword = '';" 
                   placeholder="Confirm your Password"
                   v-model="confirmPassword"
                   v-on:focus="clearValidation('confirmPassword')">
            <div class="input-group-append">
              <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                <span slot="tip">{{ isPasswordVisible.confirmPassword ? 'Hide password' : 'Show password' }}</span>
                <button slot="reference" 
                        class="btn btn--outline-primary m-0" 
                        type="button" 
                        @click="togglePasswordVisibility('confirmPassword')">
                  <i :class="['icon', isPasswordVisible.confirmPassword ? 'icon-eye-slash' : 'icon-eye']"></i>
                </button>
              </mdb-tooltip>
            </div>
            <div class="invalid-feedback" v-for="error in validation.invalid.ConfirmPassword">{{ error }}</div>
          </div>

          <div class="btns-nav mt-5 d-flex align-items-center flex-wrap">
            <button type="button" :disabled="btnForceRequest === true" class="btn btn--primary w-100 m-0" name="button" @click="sendNewPassword">Continue <i class="icon icon-arrow-right ml-2 align-middle"></i></button>
          </div>
        </div>
      </div>
      <div v-if="this.section === 2">
        <p>The link that was sent to you, has been expired on <strong class="alert-link">{{ this.expireDate }}</strong>.</p>
        <p>If you want to confirm your <strong class="alert-link">{{ this.maskEmail }}</strong> please <a @click="sendSecurityCode" v-bind:class="{'blocked': btnForceRequest}" class="click-link">click here</a></p>
      </div>
      <div v-if="this.section === 3">
        <h2 class="form-title font-weight-bold">Enter Security Code</h2>
        <p class="mb-0">Enter the {{ this.confirmExpireEmailData.lengthSecurityCode }}-digit code sent to <span class="font-weight-bold">{{ this.maskEmail }}</span></p>
        <p class="mb-5">Did not receive an email? <a class="click-link" v-bind:class="{'blocked': this.btnForceRequest}" @click="resendCode">Resend code </a></p>
        <div class="w-100">
          <div class="form-group">
            <input :maxlength="this.confirmExpireEmailData.lengthSecurityCode" :readonly="btnForceRequest" type="text" v-bind:class="{'is-invalid': validation.invalid.SecurityCode }" class="form-control"
                   v-on:input="validation.invalid.SecurityCode = '';"
                   placeholder="XXXX-XXXX" v-model="securityCode"
                   v-on:focus="clearValidation('securityCode')">
            <div class="invalid-feedback" v-for="error in validation.invalid.SecurityCode">{{ error }}</div>
          </div>

          <div class="btns-nav mt-5 d-flex align-items-center flex-wrap">
            <a @click="goToStep(2)" class="btn--back btn m-0 p-0 text-left"><i class="icon icon-arrow-left mr-2 align-middle"></i>Go Back</a>
            <button type="button" :disabled="btnForceRequest" @click="applySecurityCode" class="btn btn--primary m-0" name="button">Apply</button>
          </div>
        </div>
      </div>

      <div v-if="this.section === 4">
        <h2 class="form-title font-weight-bold">Enter New Password</h2>
        <div class="w-100">
          <div class="form-group">
            <label for="Password">New Password*</label>
            <input type="password" id="newPassword" class="form-control" :disabled="btnForceRequest" v-bind:class="{'is-invalid': validation.invalid.NewPassword }" v-model="newPassword" v-on:focus="clearValidation('NewPassword')"
                   @focus="passRequirementsVisible = true"
                   @input="passwordCheck" placeholder="Enter your Password">
            <div class="invalid-feedback" v-for="error in validation.invalid.NewPassword">{{ error }}</div>
            <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : newPassword.length > 7}"><i class="frmIcon" :class="newPassword.length > 7 ? 'icon-checkmark' : 'icon-close'"></i>at least 8 characters</p>
            <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }"><i class="frmIcon" :class="has_uppercase ? 'icon-checkmark' : 'icon-close'"></i>upper case characters (A-Z)</p>
            <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }"><i class="frmIcon" :class="has_lowercase ? 'icon-checkmark' : 'icon-close'"></i>lower case characters (a-z)</p>
            <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_number }"><i class="frmIcon" :class="has_number ? 'icon-checkmark' : 'icon-close'"></i>numbers (0-9)</p>
            <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_special }"><i class="frmIcon" :class="has_special ? 'icon-checkmark' : 'icon-close'"></i>special symbols (! , ? @ $ & * etc.)</p>
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password*</label>
            <input id="confirmPassword" type="password" v-bind:class="{'is-invalid': validation.invalid.ConfirmPassword }" :disabled="btnForceRequest" class="form-control" v-on:input="validation.invalid.ConfirmPassword = '';"
                   placeholder="Confirm your Password"
                   v-model="confirmPassword"
                   v-on:focus="clearValidation('confirmPassword')">
            <div class="invalid-feedback" v-for="error in validation.invalid.ConfirmPassword">{{ error }}</div>
          </div>

          <div class="btns-nav mt-5 d-flex align-items-center flex-wrap">
            <button type="button" :disabled="btnForceRequest" class="btn btn--primary w-100 m-0" name="button" @click="setNewPasswordWithExpireLinkData">
              Continue <i class="icon icon-arrow-right ml-2 align-middle"></i></button>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>

import {post} from '@/services/http-common';
import {ref} from "vue";

export default {
  name: "guest-forgot-password",
  props: {
    forgeryToken: ref(String),
    hashRequest: ref(String),
    newPasswordPath: ref(String),
    inputExpireDate: ref(String),
    inputExpireState: ref(String),
    inputMaskEmail: ref(String),
    inputSendConfirmCodePath: ref(String),
    inputResendSecurityCodePath: ref(String),
    inputApplySecurityCodePath: ref(String),
    inputSetNewPasswordForExpireProcessPath: ref(String)
  },
  data() {
    return {
      setNewPasswordForExpireProcessPath: null,
      forgeryHeaderToken: null,
      newPassword: "",
      confirmPassword: null,
      setNewPasswordPath: null,
      sendConfirmCodePath: null,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      btnForceRequest: false,
      passRequirementsVisible: false,
      hash: null,
      expireDate: null,
      isExpired: null,
      section: null,
      maskEmail: null,
      confirmExpireEmailData: {
        lengthSecurityCode: null,
        hash: null
      },
      securityCode: "",
      formPathEmailResendCode: null,
      validation: {
        invalid: {},
        valid: {}
      },
      isPasswordVisible: {
        password: false,
        confirmPassword: false,
      },
    }
  },
  mounted() {
    this.hash = this.hashRequest;
    this.setNewPasswordPath = this.newPasswordPath;
    this.forgeryHeaderToken = this.forgeryToken;
    this.expireDate = this.inputExpireDate;
    this.isExpired = this.inputExpireState.toLowerCase() === "true"
    this.section = this.getDefaultSection();
    this.maskEmail = this.inputMaskEmail;
    this.sendConfirmCodePath = this.inputSendConfirmCodePath;
    this.formPathEmailResendCode = this.inputResendSecurityCodePath;
    this.formPathEmailApplyCode = this.inputApplySecurityCodePath;
    this.setNewPasswordForExpireProcessPath = this.inputSetNewPasswordForExpireProcessPath;
  },
  methods: {
    sendNewPassword() {
      this.btnForceRequest = true;
      //Process to confirm the aggregate 
      let process = this.$toast.warning("Processing...");


      let data = new FormData();
      data.append("hash", this.hash);
      data.append("newPassword", this.newPassword);
      data.append("confirmPassword", this.confirmPassword);

      let headers = {
        "response_location_status": "ok",
        "token": this.forgeryHeaderToken
      };
      post(this.setNewPasswordPath, data, headers)
          .then((response) => {
            if (response.status === 200) {
              process.dismiss();
              this.$toast.success('The password has been updated successfully!\n Redirecting...');
              if (response.data.Location !== undefined) {
                window.location.href = response.data.Location[0];
              }
            }
            this.btnForceRequest = false;
          })
          .catch((error) => {
            this.btnForceRequest = false;
            process.dismiss();
            if (error.response) {
              if (error.response.status >= 300 && error.response.status <= 309) {
                this.$toast.success('The password has been updated successfully!\n Redirecting...');
                window.location.href = error.response.data.Location[0];
              }
              // Request made and server responded
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },
    passwordCheck() {
      this.has_number = /\d/.test(this.newPassword);
      this.has_lowercase = /[a-z]/.test(this.newPassword);
      this.has_uppercase = /[A-Z]/.test(this.newPassword);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.newPassword);
    },
    clearValidation(field) {
      this.validation.valid[field] = '';
      this.validation.invalid[field] = '';
      this.$forceUpdate();
    },

    goToStep(stepIndex) {

      if (stepIndex >= 0) {
        this.section = stepIndex;
        return;
      }

      this.$toast.error("Invalid operation. The index must be > 0");
    },

    getDefaultSection() {
      if (this.isExpired) {
        return 2;
      }

      return 1;
    },

    sendSecurityCode() {
      this.btnForceRequest = true;
      //Process to confirm the aggregate 
      let process = this.$toast.warning("Processing...");
      let headers = {
        "token": this.forgeryHeaderToken
      };
      let data = new FormData();
      data.append("hash", this.hash);

      post(this.sendConfirmCodePath, data, headers)
          .then((response) => {
            this.btnForceRequest = false;
            process.dismiss();
            if (response.status === 200) {
              this.$toast.success(`Operation was successfully. Please check your ${this.maskEmail} and follow the instructions`)
              this.confirmExpireEmailData.lengthSecurityCode = response.data.lengthSecurityCode;
              this.confirmExpireEmailData.hash = response.data.hash;
              this.securityCode = null;
              this.goToStep(3);
            } else {
              this.$toast.error(`Invalid Operation`);
            }
          })
          .catch((error) => {
            this.btnForceRequest = false;
            process.dismiss();
            if (error.response) {
              this.$toast.error(error.response.data);
            } else {
              this.$toast.error("Invalid Request");
            }
          });
    },
    applySecurityCode() {
      this.btnForceRequest = true;
      //Process to confirm the aggregate 
      let process = this.$toast.warning("Processing...");
      let headers = {
        "token": this.forgeryHeaderToken
      };
      let data = new FormData();
      data.append("securityCode", this.securityCode);
      data.append("hash", this.confirmExpireEmailData.hash);

      post(this.formPathEmailApplyCode, data, headers)
          .then((response) => {
            process.dismiss();
            if (response.status === 200) {
              this.$toast.success(`Security code was successfully applied. Set the new password`);
              this.goToStep(4);
            }
            this.btnForceRequest = false;
          })
          .catch((error) => {
            this.btnForceRequest = false;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },
    resendCode() {
      this.btnForceRequest = true;
      let process = this.$toast.warning("Processing...");
      let headers = {
        "token": this.forgeryHeaderToken
      };
      let data = new FormData();
      data.append("Hash", this.confirmExpireEmailData.hash);

      post(this.formPathEmailResendCode, data, headers)
          .then((response) => {
            process.dismiss();
            if (response.status === 200) {
              this.$toast.success('The security code has been sent successfully');
              this.confirmExpireEmailData.hash = response.data.hash;
            } else {
              this.$toast.error("Invalid Operation");
            }
            this.btnForceRequest = false;
          })
          .catch((error) => {
            this.btnForceRequest = false;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },

    setNewPasswordWithExpireLinkData() {

      this.btnForceRequest = true;
      let process = this.$toast.warning("Processing...");
      let headers = {
        "token": this.forgeryHeaderToken,
        "response_location_status": "ok",
      };
      let data = new FormData();
      data.append("Hash", this.confirmExpireEmailData.hash);
      data.append("GuestExpireRequestId", this.hash)
      data.append("newPassword", this.newPassword);
      data.append("confirmPassword", this.confirmPassword);

      post(this.setNewPasswordForExpireProcessPath, data, headers)
          .then((response) => {
            if (response.status === 200) {
              process.dismiss();
              this.$toast.success('The password has been updated successfully!\n Redirecting...');
              if (response.data.Location !== undefined) {
                window.location.href = response.data.Location[0];
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.btnForceRequest = false;
          })
    },

    togglePasswordVisibility(field) {
      this.isPasswordVisible[field] = !this.isPasswordVisible[field];
    },
  }
}
</script>

<style lang="scss" scoped>
.click-link {
  font-weight: bold;
  color: #d6533c !important;

  &.blocked {
    color: #ccc !important;
  }
}
</style>
 