<template>
  <div>
    <form class="white-box" @keydown.enter.prevent="sendToServer">
        <h3 class="mb-2">Personal Info:</h3>
        <p>Changing any of the fields below will be relfected across all our services</p>
        <div class="form-group">
            <label asp-for="First Name" class="font-weight-bold">First Name</label>
            <input class="form-control" asp-for="First Name" v-model="firstName" @input="activateBtns" v-bind:class="{'is-invalid': validation.invalid.UpdatedFirstName }"  v-on:focus="clearValidation('UpdatedFirstName')">
            <div class="invalid-feedback" v-bind:key="index" v-for="(error, index) in validation.invalid.UpdatedFirstName">{{ error }}</div>
        </div>
          <div class="form-group">
            <label asp-for="Last Name" class="font-weight-bold">Last Name</label>
            <input class="form-control" asp-for="Last Name" v-model="lastName" @input="activateBtns" v-bind:class="{'is-invalid': validation.invalid.UpdatedLastName }" v-on:focus="clearValidation('UpdatedLastName')">
            <div class="invalid-feedback" v-bind:key="index" v-for="(error, index) in validation.invalid.UpdatedLastName">{{ error }}</div>
        </div>
    </form>
    <div class="btns-group d-flex justify-content-center align-items-center justify-content-sm-end">
      <mdb-btn type="button" class="btn--back ml-sm-auto" rounded  @click="onCancelClicked" :class="[activeBtns ? 'd-block' : 'd-none']">Cancel</mdb-btn>

      <vue-button-spinner
        :is-loading="isLoading" 
        :disabled="isLoading">
        <mdb-btn type="button" rounded 
          :class="[activeBtns ? 'btn--primary' : 'btn--disabled disabled']" 
          :disabled="isLoading"
          @click="sendToServer">Save Changes</mdb-btn>
      </vue-button-spinner>
    </div>

    <return-back-modal ref="modalComponent" 
      @goBack="sendTitle"></return-back-modal>
  </div>
</template>

<script>
import { ref } from 'vue';
import { post } from '../../../services/http-common.js';
import returnBackModal from '../../return-back-modal.vue';
import VueButtonSpinner from 'vue-button-spinner';

export default {
  components: { returnBackModal, VueButtonSpinner },
  name: 'PersonalName',
  data() {
    return {
      activeBtns: false,
      isLoading: false,
      firstName: this.firstNameProp,
      lastName: this.lastNameProp,
      saveNamesPath: this.saveNamesPathProp,
      stogeryTokenName: this.stogeryTokenNameProp,
      stogeryToken: this.stogeryTokenProp,
      validation: {
        invalid: {},
        valid: {}
      }
    }
  },
  props: {
    firstNameProp: ref(String),
    lastNameProp: ref(String),
    saveNamesPathProp: ref(String),
    stogeryTokenNameProp: ref(String),
    stogeryTokenProp: ref(String)
  },
  methods: {
    clearValidation: function(field) {
        this.validation.invalid[field] = '';
        this.validation.valid[field] = '';
        this.$forceUpdate();
    },
    sendTitle() {
      this.$emit("showComponent", 'Personal Info');
    },
    // opening returnBackModal
    onCancelClicked() {
      console.log('dsa');
      this.$refs.modalComponent.showModal(true);
    },
    activateBtns() {
      this.activeBtns = true;
    },
    sendToServer() {
      let data = new FormData();
      data.append('UpdatedFirstName', this.firstName);
      data.append('UpdatedLastName', this.lastName);
      this.isLoading = true;

      post(this.saveNamesPath, data, this.stogeryToken)
        .then((response) => {
          this.$emit("updatedNames", response.data.updatedFirstName, response.data.updatedLastName);
          this.sendTitle();

          const userName = document.getElementById("user-name");
          userName.innerText = this.firstName + ' ' + this.lastName

          const namesByChars = document.getElementsByClassName("avatar");
          namesByChars.forEach(name => {
            name.innerText = this.firstName.charAt(0) + this.lastName.charAt(0)
          });

          this.isLoading = false;
          this.$toast.success('Name saved.', {
              // optional options Object
          })
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error("The name couldn't be saved.", {
              // optional options Object
          })
          if (error.response) {
            // Request made and server responded
            this.validation.invalid = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        })
    }
  }
}
</script>