<template>
  <main class="main d-flex flex-column flex-lg-row">
    <section class="wrapper d-flex flex-column col-lg-6">

      <div class="d-flex d-none d-sm-block align-items-center justify-content-center justify-content-lg-start" v-if="isLayoutVisaFirstApi">
        <p class="text-white mb-0 mr-3 fs_22">Powered by</p>
        <div class="logo align-self-center align-self-lg-start mb-5"></div>
      </div>
      <div class="logo align-self-center align-self-lg-start mb-5" v-else></div>

      <h1 class="d-none d-sm-block text-center text-lg-left">Sign Up</h1>

      <ul id="progressbar" class="progress-bar__container p-0 mb-0 align-self-center">
        <li class="progress-bar__item" :class="activePhase === 1 ? 'current' : 'complete'" id="personal-dot">Personal Info</li>
        <li class="progress-bar__item" :class="[(activePhase === 2 ? 'current' : ''), (activePhase === 3 ? 'complete' : '')]" id="account-dot">Create Account</li>
        <li class="progress-bar__item pb-0" :class="activePhase === 3 ? 'current' : ''" id="confirm-dot">Confirm E-mail</li>
      </ul>
    </section>

    <section class="wrapper wrapper-enroll d-flex flex-column align-items-center justify-content-center col-lg-6">
      <div class="form-wrapper w-100">
        <!-- v-on:submit.prevent="validate" -->
        <form v-on:submit.prevent>
          <!-- Step 1-->
          <div class="enroll-step" id="step1" v-if="activePhase === 1">
            <h2 class="form-title font-weight-bold">Tell us about yourself</h2>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <label for="firstName">First Name*</label>
                  <input type="text" id="firstName" class="form-control" v-bind:class="{'is-invalid': validation.invalid.firstName }" v-model="firstName" v-on:focus="clearValidation('firstName')"
                         placeholder="First Name">
                  <div class="invalid-feedback" v-if="validation.invalid.firstName">{{ validation.invalid.firstName }}</div>
                </div>
              </div>

              <div class="col-xl-6">
                <div class="form-group">
                  <label for="firstName">Last Name*</label>
                  <input type="text" id="lastName" class="form-control" v-bind:class="{'is-invalid': validation.invalid.lastName }" v-model="lastName" v-on:focus="clearValidation('lastName')"
                         placeholder="Last Name">
                  <div class="invalid-feedback" v-if="validation.invalid.lastName">{{ validation.invalid.lastName }}</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="email">Email Address*</label>
              <input type="email" id="email" class="form-control" v-bind:class="{'is-invalid': validation.invalid.email }" v-model="email" v-on:focus="clearValidation('email')"
                     v-on:input="validation.invalid.Email = '';" placeholder="Email Address">
              <div class="invalid-feedback" v-if="validation.invalid.email">{{ validation.invalid.email }}</div>
            </div>

            <div class="form-group">
              <label for="phoneNumber">Phone number*</label>
              <vue-tel-input
                  v-model="phoneNumber" mode="national"
                  v-bind:class="{'is-invalid': validation.invalid.phoneNumber || validation.invalid.phoneCode }"
                  v-on:focus="clearValidation('phoneNumber')"
                  :autoFormat="false"
                  defaultCountry="US"
                  :autoDefaultCountry="true"
                  :dropdownOptions="{showFlags: true, showSearchBox: true, showDialCodeInSelection: true, showDialCodeInList: true}"
                  @country-changed="onCountrySelect"></vue-tel-input>
              <div class="invalid-feedback" v-if="!this.phoneCode">{{ validation.invalid.phoneCode }}</div>
              <div class="invalid-feedback" v-if="validation.invalid.phoneNumber">{{ validation.invalid.phoneNumber }}</div>
            </div>

            <mdb-btn type="button" @click="validateStep1()" class="btn--primary w-100" rounded>Continue <img src="@/assets/img/arrow-right.svg" alt="" class="ml-2"></mdb-btn>
            <p class="mt-3 mb-0 text-center">Already have an account? <a :href="this.loginUrl" class="sign-in">Log in</a></p>
          </div>

          <!-- Step 2 -->
          <div class="enroll-step" id="step2" v-if="activePhase === 2">
            <h2 class="form-title font-weight-bold">Create Your Account</h2>
            <div role="alert" class="alert alert-danger" v-if="this.validation.invalid.Email">
              <h6 class="text-center">
                <i class="icon icon-issue align-middle mr-1"></i>
                <strong>Error</strong>
              </h6>
              <p class="mb-0 text-center" v-for="error in validation.invalid.Email">
                {{ error }}
              </p>
            </div>
            
            <div class="form-group input-group">
              <label for="Password" class="d-block w-100">Password*</label>
              <input :type="isPasswordVisible.password ? 'text' : 'password'" 
                     id="Password" 
                     class="form-control" 
                     v-bind:class="{'is-invalid': validation.invalid.Password }" 
                     v-model="Password" 
                     v-on:focus="clearValidation('Password')"
                     @focus="passRequirementsVisible = true"
                     @input="Password_check" 
                     placeholder="Your Password">
              <!-- Password field's toggle button -->
              <div class="input-group-append">
                <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                  <span slot="tip">{{ isPasswordVisible.password ? 'Hide password' : 'Show password' }}</span>
                  <button slot="reference" 
                          class="btn btn--outline-primary m-0" 
                          type="button" 
                          @click="togglePasswordVisibility('password')">
                    <i :class="['icon', isPasswordVisible.password ? 'icon-eye-slash' : 'icon-eye']"></i>
                  </button>
                </mdb-tooltip>
              </div>
              <div class="invalid-feedback" v-for="error in validation.invalid.Password">{{ error }}</div>
              <div class="w-100">
                <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : Password.length > 7}">
                  <i class="frmIcon"
                    :class="Password.length > 7 ? 'icon-checkmark' : 'icon-close'"></i>at least 8 characters
                </p>
                <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }">
                  <i class="frmIcon"
                    :class="has_uppercase ? 'icon-checkmark' : 'icon-close'"></i>upper case characters (A-Z)
                </p>
                <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }">
                  <i class="frmIcon"
                    :class="has_lowercase ? 'icon-checkmark' : 'icon-close'"></i>lower case characters (a-z)
                </p>
                <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_number }">
                  <i class="frmIcon" 
                    :class="has_number ? 'icon-checkmark' : 'icon-close'"></i>numbers (0-9)
                </p>
                <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_special }">
                  <i class="frmIcon" :class="has_special ? 'icon-checkmark' : 'icon-close'"></i>special symbols (! , ? @ $ & * etc.)
                </p>
              </div>
            </div>

            <div class="form-group input-group mt-4">
              <label for="PasswordConfirmed" class="d-block w-100">Confirm Password*</label>
              <input :type="isPasswordVisible.confirmPassword ? 'text' : 'password'" 
                     id="PasswordConfirmed" 
                     class="form-control" 
                     v-bind:class="{'is-invalid': validation.invalid.PasswordConfirmed }" 
                     v-model="PasswordConfirmed"
                     v-on:focus="clearValidation('PasswordConfirmed')"
                     placeholder="Confirm Your Password">
              <div class="input-group-append">
                <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                  <span slot="tip">{{ isPasswordVisible.confirmPassword ? 'Hide password' : 'Show password' }}</span>
                  <button slot="reference" 
                          class="btn btn--outline-primary m-0" 
                          type="button" 
                          @click="togglePasswordVisibility('confirmPassword')">
                    <i :class="['icon', isPasswordVisible.confirmPassword ? 'icon-eye-slash' : 'icon-eye']"></i>
                  </button>
                </mdb-tooltip>
              </div>
              <div class="invalid-feedback" v-for="error in validation.invalid.PasswordConfirmed">{{ error }}</div>
            </div>

            <div class="text-left checkbox">
              <h3 class="text-center my-4 py-3">Terms:</h3>

              <div class="custom-control custom-checkbox">
                <input type="checkbox" id="terms" class="custom-control-input" v-model="terms" @click="disableBtn = !disableBtn">
                <label class="custom-control-label" for="terms" v-html="this.getAgreementTextBody()"/>
              </div>

              <div class="custom-control custom-checkbox">
                <input type="checkbox" id="updates" class="custom-control-input" v-bind:class="{'is-invalid': validation.invalid.updates }" v-model="updates">
                <label class="custom-control-label" for="updates" v-html="this.getNewsTextBody()" />
              </div>
            </div>

            <div class="btns-nav mt-5 pt-4 d-flex align-items-center flex-wrap">
              <mdb-btn type="button" @click="goToStep(1)" class="btn--back m-0 p-0 text-left"><img src="../assets/img/arrow-left.svg" alt="" class="mr-2"> Go Back</mdb-btn>
              <mdb-btn type="button" @click="validate()" class="btn--primary m-0" :disabled="disableBtn">Continue <img src="@/assets/img/arrow-right.svg" alt="" class="ml-2"></mdb-btn>
              <p class="mt-3 mb-0 w-75 ml-auto">Already have an account? <a :href="this.loginUrl" class="sign-in">Log in</a></p>
            </div>
          </div>

          <!-- Step 3 -->
          <div class="enroll-step" id="step3" v-if="activePhase === 3">
            <h2 class="form-title font-weight-bold">Confirm your email:</h2>
            <div role="alert" class="alert alert-danger" v-if="this.validation.invalid.Hash">
              <p class="mb-0 text-center" v-for="error in validation.invalid.Hash">
                {{ error }}
              </p>
            </div>
            <h4 class="text-center">Enter the code from your email</h4>
            <p class="info-text mb-5 text-center">Please confirm your email ownership.<br> Enter the 8-digit code sent to <span class="font-weight-bold">{{ this.email }}</span> below </p>

            <div class="form-group">
              <input type="text" id="SecurityCode" v-bind:class="{'is-invalid': validation.invalid.SecurityCode }" v-model="SecurityCode" class="form-control code-input" placeholder="XXXX-XXXX">
              <div class="invalid-feedback" v-for="error in validation.invalid.SecurityCode">{{ error }}</div>
            </div>

            <a @click="resendEmail()" v-bind:class="{'loading': this.disableBtn}" class="resend-email d-block mt-3 mb-0 text-right">Resend confirmation email</a>

            <div class="btns-nav mt-5 pt-4 d-flex align-items-center flex-wrap">
              <mdb-btn :disabled="disableBtn" type="button" @click="goToStep(2)" class="btn--back m-0 p-0 text-left"><img src="../assets/img/arrow-left.svg" alt="" class="mr-2"> Go Back</mdb-btn>
              <mdb-btn :disabled="disableBtn" @click="confirm()" class="btn btn--primary m-0">Continue <img src="@/assets/img/arrow-right.svg" alt="" class="ml-2"></mdb-btn>
            </div>
          </div>

          <!-- Step 4 -->
          <div class="enroll-step" id="step4" v-if="activePhase === 4">
            <div role="alert" class="alert alert-danger" v-if="this.validation.invalid !== undefined && this.validation.invalid.length > 0">
              <p class="mb-0 text-center" v-for="error in validation.invalid">
                {{ error }}
              </p>
            </div>
            <h4 class="text-center">You almost done. Please confirm your account</h4>

            <div class="btns-navpt-4 d-flex align-items-center flex-wrap">
              <mdb-btn :disabled="disableBtn" @click="applyNewAccount" class="btn btn--primary m-auto">Confirm Account</mdb-btn>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import {ref} from "vue";
import {get, post} from '../services/http-common.js';
import th from "vue2-datepicker/locale/es/th";

export default {
  name: 'Register',
  props: {
    modelReturnUrl: String,
    forgeryToken: String,
    login: String,
    clientName: ref(String),
    informationLinkSource: ref(String),
    policyText: ref(String)
  },
  data() {
    return {
      countSendBadRequest: 0,
      activePhase: 1,
      countries: [],
      validation: {
        invalid: {},
        valid: {},
      },
      firstName: '',
      lastName: '',
      email: '',
      phoneCode: '',
      phoneNumber: null,
      Password: '',
      passRequirementsVisible: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      PasswordConfirmed: '',
      terms: false,
      updates: false,
      SecurityCode: '',
      forgeryTkn: '',
      hash: String,
      aggregateRootId: String,
      returnUrl: '',
      loginUrl: '',
      disableBtn: true,
      informationLinkProvider: {
        termAndConditionHref: ""
      },
      policy: {},
      isLayoutVisaFirstApi: false,
      isPasswordVisible: {
        password: false,
        confirmPassword: false,
      },
    };
  },
  mounted() {
    let informationLinkSource = JSON.parse(this.informationLinkSource);
    this.forgeryTkn = this.forgeryToken;
    this.returnUrl = this.modelReturnUrl;
    this.loginUrl = this.login;
    this.informationLinkProvider.termAndConditionHref = informationLinkSource.TermLink;
    this.policy = JSON.parse(this.policyText);
    // Check if the body element has the specified class
    if (document.body.classList.contains('layout-visa_first_api')) {
      this.isLayoutVisaFirstApi = true;
    }
  },
  methods: {
    goToStep(step) {
      this.activePhase = step;
    },
    onCountrySelect({dialCode, iso2, name}) {
      this.phoneCode = iso2;
      this.validation.invalid.phoneCode = '';
      this.validation.valid.phoneCode = '';
    },
    validateStep1() {
      if (!this.firstName) {
        this.validation.invalid.firstName = 'Please type your First Name';
      } else if (!this.firstName.match(/^[a-zA-Z\s+\-\'\`]+$/)) {
        this.validation.invalid.firstName = 'First Name should contains Only Latin letters, spaces, hyphens and apostrophes are allowed';
      } else {
        this.validation.valid.firstName = true;
      }

      if (!this.lastName) {
        this.validation.invalid.lastName = 'Please type your Last Name';
      } else if (!this.lastName.match(/^[a-zA-Z\s+\-\'\`]+$/)) {
        this.validation.invalid.lastName = 'Last Name should contains Only Latin letters, spaces, hyphens and apostrophes are allowed';
      } else {
        this.validation.valid.lastName = true;
      }

      if (!this.email) {
        this.validation.invalid.email = 'Please type your Email Address';
      } else if (!this.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        this.validation.invalid.email = 'Please enter a valid Email Address';
      } else {
        this.validation.valid.email = true;
      }

      if (!this.phoneNumber) {
        this.validation.invalid.phoneNumber = 'Please type your Phone number';
      } else if (!this.phoneNumber.match(/^[0-9]{0,}$/)) {
        this.validation.invalid.phoneNumber = 'Phone number should contains only numbers without spaces';
      } else {
        this.validation.valid.phoneNumber = true;
      }

      if (!this.phoneCode) {
        this.validation.invalid.phoneCode = 'Phone code is required';
      } else {
        this.validation.valid.phoneCode = true;
      }

      if (this.validation.valid.firstName && this.validation.valid.lastName && this.validation.valid.email && this.validation.valid.phoneNumber && this.validation.valid.phoneCode) {
        this.goToStep(2);
      }
      // force update because deep object change
      this.$forceUpdate();
    },
    clearValidation: function (field) {
      this.validation.invalid[field] = '';
      this.validation.valid[field] = '';
      this.$forceUpdate();
    },
    Password_check: function () {
      this.has_number = /\d/.test(this.Password);
      this.has_lowercase = /[a-z]/.test(this.Password);
      this.has_uppercase = /[A-Z]/.test(this.Password);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.Password);
    },
    validate() {
      if (this.disableBtn) {
        return;
      }
      this.disableBtn = true;
      let data = new FormData();
      data.append('email', this.email);
      data.append("firstName", this.firstName);
      data.append("lastName", this.lastName);
      data.append("phoneCode", this.phoneCode);
      data.append("phoneNumber", this.phoneNumber);
      data.append("Password", this.Password);
      data.append("PasswordConfirmed", this.PasswordConfirmed);
      data.append("ReturnUrl", this.returnUrl);
      data.append("IsWantToReceiveNews", this.updates);
      
      post('/Account/Signup/Aggregate', data, this.forgeryTkn)
          .then((response) => {
            if (response.status === 200) {
              this.hash = response.data.hash;
              this.aggregateRootId = response.data.aggregateRootId;
              this.returnUrl = response.data.returnUrl;
              this.disableBtn = false;
              if (response.data.isNeedFromConfirmEmail) {
                this.goToStep(3);
              } else {
                this.applyNewAccount()
              }
            }
          })
          .catch((error) => {
            this.disableBtn = false;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          });
    },
    confirm() {
      if (this.disableBtn) {
        return;
      }
      this.disableBtn = true;
      let data = new FormData();
      data.append("Hash", this.hash);
      data.append("AggregateRootId", this.aggregateRootId);
      data.append("ReturnUrl", this.returnUrl);
      data.append("SecurityCode", this.SecurityCode);

      post('/Account/Signup/Confirm', data, this.forgeryTkn)
          .then((response) => {
            if (response.status === 200) {
              this.disableBtn = true;

              //success message for the code
              this.$toast.success("Your code was confirmed successfully!");

              //Reset the counter
              this.countSendBadRequest = 0;

              setTimeout(() => {
                this.$toast.info("Applying...");
              }, 2000)

              //Confirmed was successfully
              setTimeout(this.appliedRegistrationAfterConfirmSecurityCode, 3000);
            }
          })
          .catch((error) => {
            this.disableBtn = false;
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },
    applyNewAccount() {
      if (this.disableBtn) {
        this.$toast.error("The request is not finish at");
        return;
      }
      this.disableBtn = true;

      this.applyRegistration((responseData) => {
        this.$toast.success('Your account was created successfully! Redirecting to Login Page...');
        window.location.href = responseData.actions.LoginUrl;
        this.countSendBadRequest = 0;
      }, (error) => {
        this.countSendBadRequest += 1;
        this.disableBtn = false;
        if (error.response) {
          this.validation.invalid = error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      })
    },

    appliedRegistrationAfterConfirmSecurityCode() {
      this.applyRegistration((responseData, response) => {
        if (response.isCreated) {
          this.$toast.success('Your account was created successfully! Redirecting to Login Page...');
          window.location.href = responseData.actions.LoginUrl;
          return;
        }
        this.disableBtn = false;
        //go to confirm registration section 
        this.goToStep(4);

      }, (error) => {
        this.disableBtn = false;
        if (error.response) {
          this.goToStep(4);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      })
    },

    applyRegistration(resolve, reject) {
      let data = new FormData();
      data.append("Hash", this.hash);
      data.append("AggregateRootId", this.aggregateRootId);
      data.append("ReturnUrl", this.returnUrl);
      post('/Account/Signup/Aggregate/Apply', data, this.forgeryTkn)
          .then((response) => {
            resolve(response.data, {
              status: response.status,
              isCreated: response.status === 201
            });
          })
          .catch((error) => {

            reject(error);
          })
    },

    resendEmail() {
      if (this.disableBtn) {
        return;
      }

      //Process to confirm the aggregate 
      let process = this.$toast.warning("Processing...");

      let data = new FormData();
      data.append("Hash", this.hash);
      data.append("AggregateRootId", this.aggregateRootId);
      data.append("ReturnUrl", this.returnUrl);
      this.disableBtn = true;
      post('/Account/Signup/Resend', data, this.forgeryTkn)
          .then((response) => {
            this.hash = response.data.hash;
            this.disableBtn = false;
            process.dismiss();
            this.$toast.success('The request has been send successfully.');
          })
          .catch((error) => {
            process.dismiss();
            this.disableBtn = false;
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },

    getAgreementTextBody() {
      let placeHolderKeyLinkName = '[term_and_condition_link]';
      let placeHolderKeyLink = this.policy.SignUpAgreement.PlaceHolders.find(x => x.PlaceHolderName === placeHolderKeyLinkName);
      let placeholderHref = placeHolderKeyLinkName;
      if (placeHolderKeyLink !== undefined) {
        placeholderHref = '<a target="_blank" href="' + placeHolderKeyLink.LabelValue + '">' + placeHolderKeyLink.LabelKey + '</a>';
      }
      let sentence = this.policy.SignUpAgreement.TextPlaceholder.replace(placeHolderKeyLinkName, placeholderHref);

      let allPlaceholders = this.policy.SignUpAgreement.PlaceHolders.filter(x => x.PlaceHolderName !== placeHolderKeyLinkName);

      for (let key in allPlaceholders) {
        if (!allPlaceholders.hasOwnProperty(key)) {
          continue;
        }

        let placeholder = allPlaceholders[key];
        sentence = sentence.replace(placeholder.PlaceHolderName, placeholder.LabelValue);
      }


      return sentence;
    },

    getNewsTextBody() {
      let sentence = this.policy.SignUpAgreementNews.TextPlaceholder;
      let placeholders = this.policy.SignUpAgreementNews.PlaceHolders;
      for (let key in placeholders) {
        if (!placeholders.hasOwnProperty(key)) {
          continue;
        }

        let placeholder = placeholders[key];
        sentence = sentence.replace(placeholder.PlaceHolderName, placeholder.LabelValue);
      }
      
      return sentence;
    },
    togglePasswordVisibility(field) {
      this.isPasswordVisible[field] = !this.isPasswordVisible[field];
    },
  },
};
</script>

<style lang="scss">

.loading {
  color: #ccc !important;
  cursor: not-allowed;
}

.frmValidation {
  font-size: 13px;
  margin: 0;

  &:first-of-type {
    margin-top: 10px;
  }

  &.frmValidation--passed {
    color: #9ba2a9;

    .frmIcon {
      color: #0fa140;
    }
  }

  .frmIcon {
    margin-right: 10px;
    font-size: 10px;
    color: #EB0029;
  }
}

.v-select {
  height: auto !important;

  &.is-invalid {
    .v-select-toggle {
      @include themify($themes) {
        border-color: themed('errorColor') !important;
      }
    }

    + .invalid-feedback {
      display: block;
    }
  }

  .v-select-toggle {
    justify-content: center !important;
    padding: 8px 15px !important;
    border: 2px solid #eee !important;
    border-radius: 10px !important;
    font-size: 16px !important;

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: transparent !important;
    }

    .arrow-down {
      position: absolute;
      right: 15px;
    }
  }

  .v-dropdown-container {
    max-height: 200px;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 10px !important;
    top: 0;
    -webkit-animation: showDropdown 0.2s cubic-bezier(0.6, 0.2, 0.1, 1) both;
    animation: showDropdown 0.2s cubic-bezier(0.6, 0.2, 0.1, 1) both;
    box-shadow: 0 20px 66px 0 rgb(34 48 73 / 20%);
    overflow-y: auto;

    .v-dropdown-item {
      font-size: 14px;

      &:hover {
        background-color: #f2f2f3 !important;
      }

      &.selected {
        background-color: #f2f2f3 !important;
        color: #000 !important;
      }
    }

    .v-bs-searchbox {
      .form-control {
        border: 2px solid #eee !important;
      }
    }
  }
}

@-webkit-keyframes showDropdown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10px) scale(.95, .95);
    transform: translateY(-10px) scale(.95, .95)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes showDropdown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10px) scale(.95, .95);
    transform: translateY(-10px) scale(.95, .95)
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

// datepicker
.mx-datepicker {
  width: 100%;
  display: block;

  .mx-icon-calendar {
    right: 15px;

    svg {
      fill: #1360a0;
    }
  }

  .mx-datepicker-popup {
    top: 0 !important;
    left: 0 !important;
    right: 0;
    margin: auto;
    max-width: 250px;

    .mx-calendar-content {
      .mx-table-date .today {
        font-weight: 700;
        color: #1360a0;
      }

      .cell {
        &.active {
          background-color: #1360a0;
        }
      }
    }
  }

  &.is-invalid {
    .form-control {
      @include themify($themes) {
        border-color: themed('errorColor');
      }
    }

    + .invalid-feedback {
      display: block;
    }
  }
}

</style>

<style lang="scss" scoped>
.form-wrapper {
  max-width: 600px;

  .form-title {
    margin-bottom: 70px;
  }

  .info-text {
    font-size: 14px;
    color: #6f6f6f;

    a {
      font-weight: 600;
      color: #031c2b;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .resend-email {
    font-weight: 600;
    color: #1360a0;

    &:hover {
      text-decoration: underline;
    }
  }

  .sign-in {
    text-decoration: underline;
    color: #000;

    &:hover {
      text-decoration: none;
    }
  }
}

.progress-bar__container {
  margin-top: 140px;
  counter-reset: my-sec-counter;
  @media only screen and (max-width: 991px) {
    margin-top: 70px;
  }
  @media (min-width: 576px) and (max-width: 991px) {
    display: flex;
  }
  @media only screen and (max-width: 575px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .progress-bar__item {
    padding: 0 0 120px 77px;
    display: flex;
    align-items: center;
    position: relative;
    list-style: none;
    font-weight: 400;
    line-height: 58px;
    font-size: 48px;
    @include themify($themes) {
      color: themed('progressColor');
    }
    @media only screen and (max-width: 1199px) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (min-width: 576px) and (max-width: 991px) {
      flex-direction: column;
      padding: 0 0 0 77px;
      text-align: center;
      line-height: normal;
      font-size: 24px;
    }
    @media only screen and (max-width: 575px) {
      padding-bottom: 0;
      opacity: 0;
      height: 0;
      visibility: hidden;
      padding-left: 45px;
      font-size: 24px;
    }

    &:before {
      content: counter(my-sec-counter);
      counter-increment: my-sec-counter;
      display: inline-block;
      position: absolute;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      left: 0;
      text-align: center;
      @include themify($themes) {
        border: 4px dashed themed('progressBgColor');
        background: themed('backgroundColor');
        color: themed('progressColor');
      }
      font: 700 30px/52px 'Lato', sans-serif;
      @media (min-width: 576px) and (max-width: 991px) {
        margin-bottom: 15px;
        position: relative;
        z-index: 3;
      }
      @media only screen and (max-width: 575px) {
        width: 30px;
        height: 30px;
        font-size: 18px;
        line-height: 22px;
      }
    }

    &.current {
      font-weight: 600;

      &:before {
        @include themify($themes) {
          border-color: themed('progressBgColor');
          background: themed('progressBgColor');
          color: themed('progressActiveColor');
        }
      }

      @media only screen and (max-width: 575px) {
        opacity: 1;
        visibility: visible;
        height: auto;
      }
    }

    &.complete {
      &:before {
        content: "\e902";
        font-family: 'icomoon';
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 58px;
        font-size: 24px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #fff;
        @include themify($themes) {
          color: themed('progressActiveColor');
          background: themed('progressBgColor');
        }
      }
    }

    &:not(:last-child)::after {
      content: "";
      display: block;
      position: absolute;
      width: 0px;
      height: 100px;
      @include themify($themes) {
        border: 2px dashed themed('progressBgColor');
      }
      top: 69px;
      left: 27px;
      @media only screen and (max-width: 1199px) {
        height: 90px;
        top: 66px;
      }
      @media (min-width: 576px) and (max-width: 991px) {
        width: 235px;
        height: 1px;
        top: 26px;
        right: -173px;
        left: auto;
      }
      @media only screen and (max-width: 575px) {
        display: none;
      }
    }

    &:first-child {
      @media (min-width: 576px) and (max-width: 991px) {
        padding-left: 0;
      }
    }
  }
}

</style>