<template>
    <div>
        <div class="white-box">
            <h3 class="mb-5">Reasons for closure:</h3>
            <input class="form-control" placeholder="Please state the resoon for closing your account" >
        </div>
        <div class="white-box">
            <h3 class="mb-5">Message</h3>
                <textarea class="form-control" placeholder="Please write any additional information our team might find useful" id="erasureMessage" rows="3"></textarea>
        </div>
        <div class="btns-group d-flex justify-content-center justify-content-sm-end">
            <mdb-btn type="button" class="btn--back ml-sm-auto" rounded @click="onCancelClicked">Cancel</mdb-btn>
            <mdb-btn type="button" class="btn--primary" rounded>Save Changes</mdb-btn>
        </div>
        <return-back-modal ref="modalComponent" @goBack="sendTitle"></return-back-modal>
    </div>
</template>
<script>
import returnBackModal from '../../return-back-modal.vue';
export default {
    components: { returnBackModal },
    name: 'AccountErasureRequest',
    methods: {
        sendTitle() {
            this.$emit("showComponent", 'Personal Info');
        },
        // opening returnBackModal
        onCancelClicked() {
            this.$refs.modalComponent.showModal(true);
        }
    }
}
</script>
