<template>
  <div class="container-fluid body-container white-box__container">
    <page-title :title=title
                :showBtn=showBtn
                :text=text
                parentSection="Security"
                @sendBack="setComponent"></page-title>
    <security-box @changeTitle="changeTitle"
                  :component2Prop="component2"
                  :returnSecurity="returnSecurity"
                  :section="section" :qrSrc="qrsrc"
                  :qrAlt="qralt"
                  :returnUrl="returnurl"
                  :enableMfa="enablemfa"
                  :stogeryName="stogeryname"
                  :stogeryToken="stogerytoken"
                  :disableMFAGoogleAuth="disablemfagoogleauth"
                  :mfaStatusProp="mfaStatus"
                  :disableMFABackupCode="disablemfabackupcode"
                  :getBackUpCodes="getbackupcodes"
                  :generateBackUpCodes="generatebackupcodes"
                  :removeBackUpCodes="removebackupcodes"
                  :getPasswordStatus="getpasswordstatus"
                  :editPassword="editpassword"
                  :lastPasswordChangedAt="lastpasswordchangedat"
                  :clientName="clientName"
    />
  </div>
</template>

<script>
import {ref} from 'vue';
import pageTitle from '../page-title.vue';
import SecurityBox from './components/security-box.vue';


export default {
  components: {pageTitle, SecurityBox},
  name: 'Security',
  data() {
    return {
      title: 'Security',
      showBtn: false,
      text: 'Settings to help keep your accounts secure across all our services',
      mfaStatus: this.mfastatusprop,
      component: ref(String),
      component2: 'Security',
      returnSecurity: false,
      clientName: ""
    }
  },
  mounted() {
    this.clientName = this.clientModelName;
  },
  props: {
    section: ref(String),
    qrsrc: ref(String),
    qralt: ref(String),
    returnurl: ref(String),
    stogeryname: ref(String),
    stogerytoken: ref(String),
    enablemfa: ref(String),
    disablemfagoogleauth: ref(String),
    disablemfabackupcode: ref(String),
    getbackupcodes: ref(String),
    generatebackupcodes: ref(String),
    removebackupcodes: ref(String),
    mfastatusprop: Boolean,
    getpasswordstatus: ref(String),
    editpassword: ref(String),
    lastpasswordchangedat: ref(String),
    clientModelName: ref(String)
  },
  methods: {
    changeTitle(newTitle) {
      this.title = newTitle;
      switch (newTitle) {
        case "Security":
          this.showBtn = false;
          this.text = 'Settings to help keep your accounts secure across all Sprrintax services';
          this.returnSecurity = true;
          break;
        case "Password":
          this.showBtn = true;
          this.text = ''
          this.returnSecurity = false;
          break;
        case "Backup Codes":
          this.showBtn = true;
          this.text = 'Print or save the list of codes below you can sign in with. Keep them somewhere safe and secure';
          this.returnSecurity = false;
          break;
        case "Email":
          this.showBtn = true;
          this.returnSecurity = false;
          break;
        case "Phone":
          this.showBtn = true;
          this.returnSecurity = false;
          break;
        case "2-FACTOR LOGIN":
          this.title = '2-Factor Login'
          this.showBtn = true;
          this.text = 'Use the steps below to add an extra layer of security to your account';
          console.log(this.text);
          this.returnSecurity = false;
          break;
        default:
          this.showBtn = false;
      }
    },
    // on Back button return to Security view
    setComponent(component) {
      this.component = component;
      this.changeTitle(component);
      this.returnSecurity = true;
    }
  }
}
</script>

<style lang="scss">
</style>
