<template>
  <section class="wrapper wrapper-enroll col-lg-6 d-flex flex-column align-items-center justify-content-center">

    <!--    Step 1-->
    <div id="step1" v-if="activeSection === 1">
      <div class="col-xl-10 offset-xl-1 d-flex flex-column align-items-center justify-content-center">
        <h2 class="form-title font-weight-bold">Forgot Your Password?</h2>
        <div class="w-100">
          <div class="form-group">
            <label>Email*</label>
            <input type="text" v-bind:class="{'is-invalid': validation.invalid.Email }" class="form-control" v-on:input="validation.invalid.Email = '';" placeholder="Email" v-model="email" v-on:focus="clearValidation('email')">
            <div class="invalid-feedback" v-for="error in validation.invalid.Email">{{ error }}</div>
          </div>

          <div class="btns-nav mt-5 d-flex align-items-center flex-wrap">
            <a :href="loginUrl" class="btn--back btn m-0 p-0 text-left"><i class="icon icon-arrow-left mr-2 align-middle"></i>Go Back</a>
            <button :disabled="btnForceRequest === 1" type="button" @click="createPassword" class="btn btn--primary m-0" name="button" value="ResetPassword">Reset Password</button>
          </div>
        </div>
      </div>
    </div>

    <!--    Step 2-->
    <div id="step2" v-if="activeSection === 2">
      <div class="col-xl-10 offset-xl-1 d-flex flex-column align-items-center justify-content-center">
        <h2 class="form-title font-weight-bold">Enter Security Code</h2>
        <p class="mb-0 text-center">Enter the {{ this.lengthSecurityCode }}-digit code sent to <span class="font-weight-bold">{{ this.emailWithMask }}</span></p>
        <p class="mb-5">Did not receive an email? <a href="javascript:void(0);" @click="resendCode">Resend code </a></p>
        <div class="w-100">
          <div class="form-group">
            <input :maxlength="lengthSecurityCode" :readonly="btnForceRequest === 1" type="text" v-bind:class="{'is-invalid': validation.invalid.SecurityCode }" class="form-control" v-on:input="validation.invalid.SecurityCode = '';"
                   placeholder="XXXX-XXXX" v-model="securityCode"
                   v-on:focus="clearValidation('securityCode')">
            <div class="invalid-feedback" v-for="error in validation.invalid.SecurityCode">{{ error }}</div>
          </div>

          <div class="btns-nav mt-5 d-flex align-items-center flex-wrap">
            <a :href="this.loginUrl" class="btn--back btn m-0 p-0 text-left">Cancel</a>
            <button type="button" :disabled="btnForceRequest === 1" @click="applySecurityCode" class="btn btn--primary m-0" name="button">Apply</button>
          </div>
        </div>
      </div>
      
    </div>

    <!--    Step 3-->
    <div id="step3" v-if="activeSection === 3">
      <div class="col-xl-10 offset-xl-1 d-flex flex-column align-items-center justify-content-center">
        <h2 class="form-title font-weight-bold">Enter New Password</h2>
        <div class="w-100">
          <div class="form-group input-group">
            <label for="newPassword" class="d-block w-100">New Password*</label>
            <input :type="isPasswordVisible.password ? 'text' : 'password'" 
                   id="newPassword"
                   class="form-control" 
                   v-bind:class="{'is-invalid': validation.invalid.NewPassword }" 
                   v-model="newPassword" 
                   v-on:focus="clearValidation('NewPassword')" 
                   @focus="passRequirementsVisible = true"
                   @input="passwordCheck" 
                   placeholder="Enter your Password">

            <!-- Password field's toggle button -->
            <div class="input-group-append">
              <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                <span slot="tip">{{ isPasswordVisible.password ? 'Hide password' : 'Show password' }}</span>
                <button slot="reference" 
                        class="btn btn--outline-primary m-0" 
                        type="button" 
                        @click="togglePasswordVisibility('password')">
                  <i :class="['icon', isPasswordVisible.password ? 'icon-eye-slash' : 'icon-eye']"></i>
                </button>
              </mdb-tooltip>
            </div>

            <div class="invalid-feedback" v-for="error in validation.invalid.NewPassword">{{ error }}</div>

            <div class="w-100">
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : newPassword.length > 7}"><i class="frmIcon" :class="newPassword.length > 7 ? 'icon-checkmark' : 'icon-close'"></i>at least 8 characters</p>
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }"><i class="frmIcon" :class="has_uppercase ? 'icon-checkmark' : 'icon-close'"></i>upper case characters (A-Z)</p>
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }"><i class="frmIcon" :class="has_lowercase ? 'icon-checkmark' : 'icon-close'"></i>lower case characters (a-z)</p>
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_number }"><i class="frmIcon" :class="has_number ? 'icon-checkmark' : 'icon-close'"></i>numbers (0-9)</p>
              <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_special }"><i class="frmIcon" :class="has_special ? 'icon-checkmark' : 'icon-close'"></i>special symbols (! , ? @ $ & * etc.)</p>
            </div>
          </div>

          <div class="form-group input-group">
            <label for="confirmPassword" class="d-block w-100">Confirm Password*</label>
            <input id="confirmPassword" 
                   :type="isPasswordVisible.confirmPassword ? 'text' : 'password'" 
                   v-bind:class="{'is-invalid': validation.invalid.ConfirmPassword }" 
                   class="form-control" 
                   v-on:input="validation.invalid.ConfirmPassword = '';" 
                   placeholder="Confirm your Password"
                   v-model="confirmPassword"
                   v-on:focus="clearValidation('confirmPassword')">
            <div class="input-group-append">
              <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                <span slot="tip">{{ isPasswordVisible.confirmPassword ? 'Hide password' : 'Show password' }}</span>
                <button slot="reference" 
                        class="btn btn--outline-primary m-0" 
                        type="button" 
                        @click="togglePasswordVisibility('confirmPassword')">
                  <i :class="['icon', isPasswordVisible.confirmPassword ? 'icon-eye-slash' : 'icon-eye']"></i>
                </button>
              </mdb-tooltip>
            </div>
            <div class="invalid-feedback" v-for="error in validation.invalid.ConfirmPassword">{{ error }}</div>
          </div>

          <div class="btns-nav mt-5 d-flex align-items-center flex-wrap">
            <a :href="this.loginUrl" class="btn--back btn m-0 p-0 text-left">Cancel</a>
            <button type="button" :disabled="btnForceRequest === 1" class="btn btn--primary m-0" name="button" @click="sendNewPassword">Continue <i class="icon icon-arrow-right ml-2 align-middle"></i></button>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import {ref} from "vue";
import {post} from '@/services/http-common';

export default {
  name: "forgot-password",
  props: {
    forgeryToken: ref(String),
    modelReturnUrl: ref(String),
    loginPath: ref(String),
    pathEmailInit: ref(String),
    pathEmailResendCode: ref(String),
    pathEmailApplyCode: ref(String),
    pathSetNewPassword: ref(String),
  },
  data() {
    return {
      btnForceRequest: 0,
      activeSection: 1,
      loginUrl: '',
      email: null,
      forgeryHeaderToken: null,
      returnUrl: null,
      hash: null,
      lengthSecurityCode: 155,
      emailWithMask: null,
      securityCode: null,
      formPathEmailInit: null,
      formPathEmailResendCode: null,
      formPathEmailApplyCode: null,
      formPathSetNewPassword: null,
      passRequirementsVisible: false,
      newPassword: '',
      confirmPassword: '',
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      validation: {
        invalid: {},
        valid: {}
      },
      isPasswordVisible: {
        password: false,
        confirmPassword: false,
      },
    }
  },
  mounted() {
    this.loginUrl = this.loginPath;
    this.forgeryHeaderToken = this.forgeryToken;
    this.returnUrl = this.modelReturnUrl;

    this.formPathEmailInit = this.pathEmailInit;
    this.formPathEmailResendCode = this.pathEmailResendCode;
    this.formPathEmailApplyCode = this.pathEmailApplyCode;
    this.formPathSetNewPassword = this.pathSetNewPassword;
  },
  methods: {
    goToStep(step) {
      this.activeSection = step;
    },
    createPassword() {

      this.btnForceRequest = 1;
      let data = new FormData();
      data.append("email", this.email);
      data.append("returnUrl", this.returnUrl);

      post(this.formPathEmailInit, data, this.forgeryHeaderToken)
          .then((response) => {

            if (response.status === 200) {
              this.hash = response.data.hash;
              this.emailWithMask = response.data.maskEmail;
              this.lengthSecurityCode = response.data.lengthSecurityCode;
              this.goToStep(2);
            }
            this.btnForceRequest = 0;
          })
          .catch((error) => {
            this.btnForceRequest = 0;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
      ;
    },
    resendCode() {
      this.btnForceRequest = 1;
      let data = new FormData();
      data.append("Hash", this.hash);
      // data.append("returnUrl", this.returnUrl);

      post(this.formPathEmailResendCode, data, this.forgeryHeaderToken)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success('The security code has been sent successfully', {})
              this.hash = response.data.hash;
            }
            this.btnForceRequest = 0;
          })
          .catch((error) => {
            this.btnForceRequest = 0;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },
    applySecurityCode() {
      this.btnForceRequest = 1;
      let data = new FormData();
      data.append("securityCode", this.securityCode);
      // data.append("returnUrl", this.returnUrl);
      data.append("hash", this.hash);
      post(this.formPathEmailApplyCode, data, this.forgeryHeaderToken)
          .then((response) => {
            if (response.status === 200) {
              this.goToStep(3);
            }
            this.btnForceRequest = 0;
          })
          .catch((error) => {
            this.btnForceRequest = 0;
            if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },
    sendNewPassword() {

      this.btnForceRequest = 1;
      let data = new FormData();
      data.append("Hash", this.hash);
      data.append("NewPassword", this.newPassword);
      data.append("ConfirmPassword", this.confirmPassword);
      data.append("ReturnUrl", this.returnUrl);

      post(this.formPathSetNewPassword, data, this.forgeryHeaderToken)
          .then((_) => {
          })
          .catch((error) => {
            this.btnForceRequest = 0;
            // Request made and server responded
            if (error.response.status >= 300 && error.response.status <= 309) {
              window.location.href = error.response.data.Location[0];
            } else if (error.response) {
              // Request made and server responded
              this.validation.invalid = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          })
    },

    passwordCheck() {
      this.has_number = /\d/.test(this.newPassword);
      this.has_lowercase = /[a-z]/.test(this.newPassword);
      this.has_uppercase = /[A-Z]/.test(this.newPassword);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.newPassword);
    },
    clearValidation(field) {
      this.validation.valid[field] = '';
      this.validation.invalid[field] = '';
      this.$forceUpdate();
    },
    togglePasswordVisibility(field) {
      this.isPasswordVisible[field] = !this.isPasswordVisible[field];
    },
  }
}
</script>