<template>
    <div>
        <div v-if="passwordInfoStep">
            <div class="white-box">
                <h3 class="mb-2">Current Password</h3>
                <p>The password ussed to login across all our services</p>
                <div class="form-group">
                    <label asp-for="Current Password" class="">Current Password</label>
                    <input class="form-control" :placeholder="lastChangedOnString" asp-for="Current Password" disabled>
                    <div class="invalid-feedback" v-bind:key="index" v-for="(error, index) in validation.invalid.UpdatedFirstName">{{ error }}</div>
                </div>
            </div>
            <div class="white-box">
                <h3 class="mb-2">Current Password</h3>
                <p>Click on the button below if you want to change your password</p>
                <mdb-btn type="button" class="btn--outline-primary w-100" rounded @click="getPasswordModel">Change Password <i class="icon icon-security ml-1"></i></mdb-btn>
            </div>
        </div>
        <div v-if="changePasswordStep">  
            <form class="white-box" v-if="!this.isUnknownPassword" @keydown.enter.prevent="sendToServer">
                <h3 class="mb-2">Current Password</h3>
                <p>The password you currectly use to login across all our services</p>
                <div class="form-group input-group">
                    <label asp-for="New Password" class="d-block w-100">Current Password</label>
                    
                    <input :type="isPasswordVisible.oldPassword ? 'text' : 'password'"
                           class="form-control" 
                           placeholder="Please enter your current password" 
                           asp-for="New Password" 
                           v-model="currentPassword" 
                           @input="activateBtns" 
                           v-bind:class="{'is-invalid': validation.invalid.OldPassword }"  
                           v-on:focus="clearValidation('OldPassword')">
                    <!-- Password field's toggle button -->
                    <div class="input-group-append">
                    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                        <span slot="tip">{{ isPasswordVisible.oldPassword ? 'Hide password' : 'Show password' }}</span>
                        <button slot="reference" 
                                class="btn btn--outline-primary m-0" 
                                type="button" 
                                @click="togglePasswordVisibility('oldPassword')">
                        <i :class="['icon', isPasswordVisible.oldPassword ? 'icon-eye-slash' : 'icon-eye']"></i>
                        </button>
                    </mdb-tooltip>
                    </div>
                    <div class="invalid-feedback" v-bind:key="index" v-for="(error, index) in validation.invalid.OldPassword">{{ error }}</div>
                </div>
            </form>
            <form class="white-box" @keydown.enter.prevent="sendToServer">
                <h3 class="mb-2">New Password</h3>
                <p>The new password you want to use across all our services</p>
                <div class="form-group input-group">
                    <label asp-for="New Password" class="d-block w-100">New Password</label>
                    
                    <input :type="isPasswordVisible.password ? 'text' : 'password'" 
                           class="form-control" 
                           placeholder="Please enter your new password" 
                           asp-for="New Password" 
                           v-model="newPassword" 
                           @input="Password_check" 
                           v-bind:class="{'is-invalid': validation.invalid.NewPassword }" 
                           v-on:focus="clearValidation('NewPassword')" 
                           @focus="passRequirementsVisible = true">

                    <!-- Password field's toggle button -->
                    <div class="input-group-append">
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                        <span slot="tip">{{ isPasswordVisible.password ? 'Hide password' : 'Show password' }}</span>
                        <button slot="reference" 
                                class="btn btn--outline-primary m-0" 
                                type="button" 
                                @click="togglePasswordVisibility('password')">
                            <i :class="['icon', isPasswordVisible.password ? 'icon-eye-slash' : 'icon-eye']"></i>
                        </button>
                        </mdb-tooltip>
                    </div>

                    <div class="invalid-feedback" v-bind:key="index" v-for="(error, index) in validation.invalid.NewPassword">{{ error }}</div>

                    <div class="w-100">
                        <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : newPassword.length > 7}"><i class="frmIcon" :class="newPassword.length > 7 ? 'icon-checkmark' : 'icon-close'"></i>at least 8 characters</p>
                        <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }"><i class="frmIcon" :class="has_uppercase ? 'icon-checkmark' : 'icon-close'"></i>upper case characters (A-Z)</p>
                        <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }"><i class="frmIcon" :class="has_lowercase ? 'icon-checkmark' : 'icon-close'"></i>lower case characters (a-z)</p>
                        <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_number }"><i class="frmIcon" :class="has_number ? 'icon-checkmark' : 'icon-close'"></i>numbers (0-9)</p>
                        <p v-if="passRequirementsVisible" class="frmValidation" :class="{'frmValidation--passed' : has_special }"><i class="frmIcon" :class="has_special ? 'icon-checkmark' : 'icon-close'"></i>special symbols (! , ? @ $ & * etc.)</p>
                    </div>
                </div>

                <div class="form-group input-group">
                    <label asp-for="Repeat New Password" class="d-block w-100">Repeat New Password</label>
                    <input :type="isPasswordVisible.confirmPassword ? 'text' : 'password'" 
                           class="form-control" 
                           placeholder="Please re-enter your new password" 
                           asp-for="Repeat New Password" 
                           v-model="confirmPassword" 
                           @input="activateBtns" 
                           v-bind:class="{'is-invalid': validation.invalid.ConfirmNewPassword }" 
                           v-on:focus="clearValidation('ConfirmNewPassword')">
                    <div class="input-group-append">
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                        <span slot="tip">{{ isPasswordVisible.confirmPassword ? 'Hide password' : 'Show password' }}</span>
                        <button slot="reference" 
                                class="btn btn--outline-primary m-0" 
                                type="button" 
                                @click="togglePasswordVisibility('confirmPassword')">
                            <i :class="['icon', isPasswordVisible.confirmPassword ? 'icon-eye-slash' : 'icon-eye']"></i>
                        </button>
                        </mdb-tooltip>
                    </div>
                    <div class="invalid-feedback" v-bind:key="index" v-for="(error, index) in validation.invalid.ConfirmNewPassword">{{ error }}</div>
                </div>
            </form>
            <div class="btns-group d-flex justify-content-center justify-content-sm-end">
                <mdb-btn type="button" class="btn--back ml-sm-auto" rounded @click="onCancelClicked" :class="[activeBtns ? 'd-block' : 'd-none']">Cancel</mdb-btn>

                <vue-button-spinner
                    :is-loading="isLoading" 
                    :disabled="isLoading">
                    <mdb-btn type="button" class="" rounded 
                        :class="[activeBtns ? 'btn--primary' : 'btn--disabled disabled']" 
                        :disabled="isLoading"
                        @click="sendToServer">Save Changes</mdb-btn>
                </vue-button-spinner>
            </div>
        </div>
        <return-back-modal ref="modalComponent" @goBack="sendTitle"></return-back-modal>
    </div>
</template>

<script>
import { ref } from 'vue';
import { get, post } from '../../../services/http-common.js';
import returnBackModal from '../../return-back-modal.vue';
import VueButtonSpinner from 'vue-button-spinner';

export default {
    components: { returnBackModal, VueButtonSpinner },
    name: 'changePass',
    data () {
        return {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            isUnknownPassword: ref(Boolean),
            activeBtns: false,
            passwordInfoStep: true,
            changePasswordStep: false,
            isLoading: false,
            validation: {
                invalid: {},
                valid: {}
            },
            passRequirementsVisible: false,
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            has_special: false,
            isPasswordVisible: {
                oldPassword: false,
                password: false,
                confirmPassword: false,
            },
        }
    },
    props: {
        stogeryToken: ref(String),
        returnUrl: ref(String),
        getPasswordStatus: ref(String),
        editPassword: ref(String),
        lastChangedOnString: ref(String)
    },
    methods: {
        Password_check: function () {
            this.activateBtns();
            this.has_number = /\d/.test(this.newPassword);
            this.has_lowercase = /[a-z]/.test(this.newPassword);
            this.has_uppercase = /[A-Z]/.test(this.newPassword);
            this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.newPassword);
        },
        clearValidation: function(field) {
            this.validation.invalid[field] = '';
            this.validation.valid[field] = '';
            this.$forceUpdate();
        },
        activateBtns() {
            this.activeBtns = true;
        },
        // opening returnBackModal
        onCancelClicked() {
            this.$refs.modalComponent.showModal(true);
        },
        sendTitle() {
            this.$emit("showComponent", 'Security');
        },
        getPasswordModel() {
            this.passwordInfoStep = false;
            this.changePasswordStep = true;
            var getPasswordStatus = this.getPasswordStatus + "?returnUrl=" + "(" + this.returnUrl + ")";
            get(getPasswordStatus)
            .then((response) => {
                this.isUnknownPassword = response.data.isUnknownPassword;
            })
            .catch((error) => {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            })
        },
        sendToServer() {
            let data = new FormData();
            data.append("ReturnUrl", this.returnUrl);
            if (!this.isUnknownPassword) {
                data.append("OldPassword", this.currentPassword);
            }
            data.append("NewPassword", this.newPassword);
            data.append("ConfirmNewPassword", this.confirmPassword);
            this.isLoading = true;

            post(this.editPassword, data, this.stogeryToken)
                .then((response) => {
                    var timeUpdatedPassword = new Date().toDateString().split(' ').slice(1).join(' ');
                    this.$emit("timeUpdatedPassword", timeUpdatedPassword);
                    this.sendTitle();

                    this.isLoading = false;
                    this.$toast.success('Password updated.', {
                        // optional options Object
                    })
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$toast.error("The password couldn't be updated.", {
                        // optional options Object
                    })

                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        this.validation.invalid = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                })
        },
        togglePasswordVisibility(field) {
            this.isPasswordVisible[field] = !this.isPasswordVisible[field];
        },
    },
}
</script>
