<template>
  <main class="main d-flex flex-column flex-lg-row">
    <section class="wrapper d-flex flex-column justify-content-between col-lg-6">
      <div class="text-center text-lg-left">
        <div class="logo d-none d-sm-block align-self-center align-self-lg-start mb-5"></div>
        <h1 class="my-3 my-lg-5 d-none d-sm-block">Your taxes,<br class="d-none d-md-inline"> Simplified</h1>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <img src="@/assets/img/guest.png" alt="" class="img-fluid align-self-center d-none d-lg-block">
          </div>
        </div>
      </div>
    </section>

    <section class="wrapper wrapper-enroll d-flex flex-column align-items-center justify-content-center col-lg-6">
      <div class="form-wrapper w-100">
        <form>
          <div class="enroll-step">
            <h2 class="form-title font-weight-bold">Guest Login</h2>

            <div class="form-group">
              <label for="firstName">First Name*</label>
              <input type="text" id="firstName" class="form-control" v-bind:class="{'is-invalid': validation.invalid.FirstName }" v-model="FirstName" v-on:focus="clearValidation('FirstName')"
                     placeholder="First Name">
              <div class="invalid-feedback" v-for="error in validation.invalid.FirstName">{{ error }}</div>
            </div>

            <div class="form-group">
              <label for="firstName">Last Name*</label>
              <input type="text" id="lastName" class="form-control" v-bind:class="{'is-invalid': validation.invalid.LastName }" v-model="LastName" v-on:focus="clearValidation('LastName')"
                     placeholder="Last Name">
              <div class="invalid-feedback" v-for="error in validation.invalid.LastName">{{ error }}</div>
            </div>

            <div class="form-group">
              <label for="email">Email Address*</label>
              <input type="email" id="email" class="form-control" v-bind:class="{'is-invalid': validation.invalid.Email }" v-model="Email" v-on:focus="clearValidation('Email')"
                     v-on:input="validation.invalid.Email = '';" placeholder="Email Address">
              <div class="invalid-feedback" v-for="error in validation.invalid.Email">{{ error }}</div>
            </div>

            <div class="form-group">
              <label for="phoneNumber">Phone number*</label>
              <vue-tel-input v-model="phoneNumber" @country-changed="onCountrySelect" :autoFormat="false" mode="national" :autoDefaultCountry="true"
                             :dropdownOptions="{showFlags: true, showSearchBox: true, showDialCodeInSelection: true, showDialCodeInList: true}"
                             v-bind:class="{'is-invalid': validation.invalid.PhoneNumber || validation.invalid.PhoneCode }" v-on:focus="clearValidation('PhoneNumber')"></vue-tel-input>
              <div class="invalid-feedback" v-for="error in validation.invalid.PhoneCode">{{ error }}</div>
              <div class="invalid-feedback" v-for="error in validation.invalid.PhoneNumber">{{ error }}</div>
            </div>

            <div class="custom-control custom-checkbox">
              <input type="checkbox" id="terms" class="custom-control-input" v-model="terms" @click="disableBtn = !disableBtn">
              <label class="custom-control-label" for="terms" v-html="this.getAgreementTextBody()"/>
            </div>

            <div class="custom-control custom-checkbox">
              <input type="checkbox" id="updates" class="custom-control-input" v-bind:class="{'is-invalid': validation.invalid.updates }" v-model="updates">
              <label class="custom-control-label" for="updates" v-html="this.getNewsTextBody()"/>
            </div>

            <mdb-btn type="button" @click="register()" class="btn--primary w-100" rounded :disabled="disableBtn">Register <img src="@/assets/img/arrow-right.svg" alt="" class="ml-2"></mdb-btn>
            <p class="mt-3 mb-0 text-center">Already have an account? <a :href="this.loginUrl" class="sign-in">Log in</a></p>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import {ref} from "vue";
import {get, post} from '../services/http-common.js';

export default {
  name: 'RegisterGuest',
  props: {
    modelReturnUrl: String,
    forgeryToken: String,
    login: String,
    clientName: ref(String),
    informationLinkSource: ref(String),
    policyText: ref(String)
  },
  data() {
    return {
      countries: [],
      validation: {
        invalid: {},
        valid: {},
      },
      FirstName: '',
      LastName: '',
      Email: '',
      phoneCode: '',
      phoneNumber: null,
      terms: false,
      updates: false,
      forgeryTkn: '',
      hash: String,
      aggregateRootId: String,
      returnUrl: '',
      loginUrl: '',
      disableBtn: true,
      informationLinkProvider: {
        termAndConditionHref: ""
      },
      policy: {
        SignUpAgreement: {
          TextPlaceholder: "",
          PlaceHolders: []
        },
        SignUpAgreementNews: {
          TextPlaceholder: "",
          PlaceHolders: []
        }
      }
    };
  },
  mounted() {
    this.forgeryTkn = this.forgeryToken;
    this.returnUrl = this.modelReturnUrl;
    this.loginUrl = this.login;

    let informationLinkSource = JSON.parse(this.informationLinkSource);
    this.informationLinkProvider.termAndConditionHref = informationLinkSource.TermLink;
    this.policy = JSON.parse(this.policyText);
  },
  methods: {

    onCountrySelect({dialCode, iso2, name}) {
      this.phoneCode = iso2;
      this.validation.invalid.PhoneCode = '';
      this.validation.valid.PhoneCode = '';
    },
    clearValidation: function (field) {
      this.validation.invalid[field] = '';
      this.validation.valid[field] = '';
      this.$forceUpdate();
    },
    register() {
      let data = new FormData();
      data.append('email', this.Email);
      data.append("firstName", this.FirstName);
      data.append("lastName", this.LastName);
      data.append("phoneCode", this.phoneCode);
      data.append("phoneNumber", this.phoneNumber);
      data.append("ReturnUrl", this.returnUrl);
      data.append("IsWantToReceiveNews", this.updates);
      

      post('/Account/Signup/Guest', data, this.forgeryTkn)
          .then((response) => {
          })
          .catch((error) => {
            if (error.response) {
              // Request made and server responded`
              this.validation.invalid = error.response.data;
              if (error.response.status >= 300 && error.response.status <= 309) {
                window.location.href = error.response.data.Location[0];
              }
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          });
    },

    getAgreementTextBody() {
      let placeHolderKeyLinkName = '[term_and_condition_link]';
      let placeHolderKeyLink = this.policy.SignUpAgreement.PlaceHolders.find(x => x.PlaceHolderName === placeHolderKeyLinkName);
      let placeholderHref = placeHolderKeyLinkName;
      if (placeHolderKeyLink !== undefined) {
        placeholderHref = '<a target="_blank" href="' + placeHolderKeyLink.LabelValue + '">' + placeHolderKeyLink.LabelKey + '</a>';
      }
      let sentence = this.policy.SignUpAgreement.TextPlaceholder.replace(placeHolderKeyLinkName, placeholderHref);

      let allPlaceholders = this.policy.SignUpAgreement.PlaceHolders.filter(x => x.PlaceHolderName !== placeHolderKeyLinkName);

      for (let key in allPlaceholders) {
        if (!allPlaceholders.hasOwnProperty(key)) {
          continue;
        }

        let placeholder = allPlaceholders[key];
        sentence = sentence.replace(placeholder.PlaceHolderName, placeholder.LabelValue);
      }

      return sentence;
    },
    getNewsTextBody() {
      let sentence = this.policy.SignUpAgreementNews.TextPlaceholder;
      let placeholders = this.policy.SignUpAgreementNews.PlaceHolders;
      for (let key in placeholders) {
        if (!placeholders.hasOwnProperty(key)) {
          continue;
        }

        let placeholder = placeholders[key];
        sentence = sentence.replace(placeholder.PlaceHolderName, placeholder.LabelValue);
      }

      return sentence;
    }
  }
}
</script>

<style lang="scss" scoped>
.form-wrapper {
  max-width: 600px;

  .form-title {
    margin-bottom: 70px;
  }

  .sign-in {
    text-decoration: underline;
    color: #000;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>