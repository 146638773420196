<template>
    <div>
        <carousel
            :items="1"
            :nav="true"
            :navText="[`<i class='icon icon-chevron-left'></i>`,`<i class='icon icon-chevron-right'></i>`]"
            :autoplayTimeout="5000"
            :autoplayHoverPause="true"
            :loop="true"
        >
            <a
             v-for="(slide, index) in slides"
             :key="index"
             :href="slide.link"
             target="_blank"
             :class="slide.className"
             class="slider-item white-box text-center m-0"
            >
                <div class="d-flex align-items-center justify-content-center">
                    <h3>{{ slide.title }}</h3>
                </div>
                <p>{{ slide.description }}</p>
            </a>
        </carousel>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import carousel from 'vue-owl-carousel'

    export default {
    components: { carousel },
    name: 'SliderComponent',
    data() { 
        return {
            slides: [
                {
                    title: 'Sprintax Returns',
                    description:
                        'Sprintax Returns is the only online solution for nonresident federal tax e-filing and state tax return preparation',
                    link: 'https://www.sprintax.com/returns/',
                    className: 'sprintax-logo',
                },
                {
                    title: 'Sprintax Forms',
                    description:
                        'Starting a new job in the US? Correctly prepare the pre-employment tax forms (W-4, 8233, W-8BEN and more) with Sprintax Forms',
                    link: 'https://www.sprintax.com/forms/',
                    className: 'sprintax-logo',
                },
                {
                    title: 'Sprintax Payments',
                    description:
                        'Your tuition payments made easy! Sprintax Payments is proud to partner with TransferMate to save you money on global payments',
                    link: 'https://www.sprintax.com/payments/',
                    className: 'sprintax-logo',
                },
                {
                    title: 'Irish PAYE',
                    description:
                        'PAYE stands for ‘Pay As You Earn’. If you are an employee, you may be entitled to tax credits, tax reliefs or exemptions',
                    link: 'https://www.taxback.com/en/ireland/paye-tax-refunds/',
                    className: 'irish-logo',
                },
                {
                    title: 'Irish Tax Return',
                    description:
                        'File with Taxback today and our expert team will look after all the paperwork. 100% Compliance with Revenue guaranteed!',
                    link: 'https://www.taxback.com/en/ireland/',
                    className: 'irish-logo',
                },
                {
                    title: 'Visa First',
                    description:
                        'Visa First, your one-stop shop for visa and work permit applications. Visa and immigration services - easy, fast and convenient!',
                    link: 'https://www.visafirst.com/',
                    className: 'visa-logo',
                },
                {
                    title: 'Financial Wellbeing',
                    description:
                        "Our unique employee benefit offering will help support your employees' financial wellbeing.",
                    link: 'https://www.monewell.com/',
                    className: 'wellbeing-logo',
                },
            ],
            icon: this.iconProp
        }   
    },
    mounted() {
        this.reorderSlides();
    },
    props: {
      iconProp: ref(String),
    },
    methods: {
        reorderSlides() {
            const bodyClassList = document.body.classList;

            // Define the mapping of body classes to the corresponding slide titles
            const prioritySlides = {
                'layout-sprintax_2_0': 'Sprintax Returns',
                'layout-swiss_dwt_service': 'Sprintax Returns',
                'layout-au_pair': 'Sprintax Returns',
                'layout-taxback_client': 'Irish PAYE',
                'layout-monewell': 'Financial Wellbeing',
                'layout-visa_first_api': 'Visa First',
                'layout-visa_first_fg': 'Visa First',
                'layout-visa_first_crm': 'Visa First',
            };

            // Check for matching body class
            for (const [bodyClass, slideTitle] of Object.entries(prioritySlides)) {
                if (bodyClassList.contains(bodyClass)) {
                    // Reorder slides: bring the matching slide to the front
                    const index = this.slides.findIndex((slide) => slide.title === slideTitle);
                    if (index !== -1) {
                        const [matchedSlide] = this.slides.splice(index, 1);
                        this.slides.unshift(matchedSlide);
                    }
                    break; // Stop after finding the first match
                }
            }
        },
    },
}
</script>

<style lang="scss">
    .section-title {
        @include respond-to(max-width, 991px) {
            text-align: center;
        } 
    }
    .owl-theme {
        .owl-dots {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 13px;

            .owl-dot {
                span {
                    background: white;
                    border: 1px solid;
                    @include themify($themes) {
                        border-color: themed('sidebarActiveItem')!important;
                    }
                }
                &.active span,
                &:hover span {
                    @include themify($themes) {
                        background: themed('sidebarActiveItem')!important;
                    }
                }
            }
        }
        .owl-prev {
            left: 15px;
            position: absolute;
            top: 40%;
            background: none !important;
            font-size: 24px !important;
            @include themify($themes) {
                color: themed('sidebarActiveItem')!important;
            }
            @include respond-to(max-width, 991px) {
                display: none!important;
            } 
        }
        .owl-next {
            right: 15px;
            position: absolute;
            top: 40%;
            background: none !important;
            font-size: 24px !important;
            @include themify($themes) {
                color: themed('sidebarActiveItem')!important;
            }
            @include respond-to(max-width, 991px) {
                display: none!important;
            } 
        }
    }

    .slider-item {
        min-height: 210px;
        padding: 25px 80px 60px;
        display: inherit;
        @include respond-to(max-width, 991px) {
            padding: 25px 25px 50px 25px;
            min-height: 315px;
        } 
        &.sprintax-logo {
            background: url('../../../assets/img/product-logos/sprintax.png') no-repeat 44% center;
            background-color: #fff;
            
        }
        &.visa-logo {
            background: url('../../../assets/img/product-logos/visa.png') no-repeat 44% center;
            background-color: #fff;
            
        }
        &.irish-logo {
            background: url('../../../assets/img/product-logos/irish.png') no-repeat 44% bottom;
            background-color: #fff;
            
        }
        &.wellbeing-logo {
            background: url('../../../assets/img/product-logos/wellbeing.png') no-repeat 44% bottom;
            background-color: #fff;
        }
        img {
            width: 30px!important;
            height: auto;
        }
        h3 {
            margin-left: 30px;
            color: #031C2B;
            @include respond-to(max-width, 991px) {
                margin-left: 10px;
            } 
        }
        p {
            margin-top: 32px;
        }
    }
</style>